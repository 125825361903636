<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="content min-height m-3">
      <div class="back-grid">
        <back-button></back-button>
        <div class="flex flex-column lg:flex-row xl:align-items-center">
          <div>
            <h2
              style="text-align: center; font-weight: bold"
              class="flex-grow-1"
            >
              Selected Practitioners
            </h2>
          </div>
          <div class="flex-shrink-1 flex-grow-0 mr-3 layout-button">
            <DataViewLayoutOptions v-model="FeedStore.recommendationLayout" />
          </div>
        </div>
      </div>
      <div class="padding-10 mt-4">
        <div v-if="RecommendationsStore.recommendations.length > 0">
          <DataView
            :value="RecommendationsStore.recommendations"
            :layout="FeedStore.recommendationLayout"
          >
            <!-- List Layout -->
            <template #list="{ items }">
              <div
                v-for="(item, index) in items"
                :key="item?.id || index"
                class="col-12 recommendation-list"
              >
                <div
                  v-if="item"
                  class="flex flex-column xl:flex-row xl:align-items-center p-4 gap-4 flex-grow-1"
                >
                  <div
                    class="flex flex-column xl:flex-row xl:align-items-center cursor-pointer"
                    @click="viewIndustryPractitioner(course, item)"
                  >
                    <!-- Priority Label -->
                    <div class="priority">
                      <span v-if="index === 0"
                        >{{ index + 1 }}<sup>st</sup></span
                      >
                      <span v-else-if="index === 1"
                        >{{ index + 1 }}<sup>nd</sup></span
                      >
                      <span v-else-if="index === 2"
                        >{{ index + 1 }}<sup>rd</sup></span
                      >
                      <span v-else>{{ index + 1 }}<sup>th</sup></span>
                      Choice
                    </div>

                    <!-- Practitioner Image -->
                    <div class="image-container">
                      <img
                        v-if="item.fileName"
                        id="profile-img"
                        :src="websiteURL + 'profile_picture/' + item.fileName"
                        class="recommendation-img-card"
                      />
                      <img
                        v-else
                        id="profile-img"
                        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                        class="recommendation-img-card"
                      />
                    </div>

                    <!-- Practitioner Details -->
                    <div class="flex-grow-1">
                      {{ item.title }} {{ item.firstName }} {{ item.lastName }},
                      {{ item.position }} at {{ item.nameOrg }},
                      {{ item.city }} {{ item.state }}
                      <br />
                      Area of Specialization:
                      {{ JSON.parse(item.expertise || "[]").join(", ") }}
                      <br />
                      Years of Experience: {{ item.yearsOfExp }}
                      <br />
                      Previous course-support provided:
                      {{ item.pastExperience }}
                    </div>

                    <!-- Status Label -->
                    <div
                      v-if="item.status === '60'"
                      class="flex-shrink-1 flex-grow-0 bold-text"
                    >
                      This Practitioner accepted the request.
                    </div>
                  </div>
                </div>
                <div v-else class="text-danger">Invalid Practitioner Data</div>
              </div>
            </template>

            <!-- Grid Layout -->
            <template #grid="{ items }">
              <div
                v-for="(item, index) in items"
                :key="item?.id || index"
                class="col-12 sm:col-6 lg:col-12 xl:col-4 p-2"
              >
                <div
                  v-if="item"
                  class="p-3 border-1 surface-border surface-card border-round cursor-pointer"
                >
                  <!-- Practitioner Image -->
                  <div>
                    <img
                      v-if="item.fileName"
                      id="profile-img"
                      :src="websiteURL + 'profile_picture/' + item.fileName"
                      class="recommendation-img-card"
                    />
                    <img
                      v-else
                      id="profile-img"
                      src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                      class="recommendation-img-card"
                    />
                  </div>

                  <!-- Practitioner Details -->
                  <div class="text-align-center">
                    <div>
                      {{ item.title }} {{ item.firstName }} {{ item.lastName }}
                    </div>
                    <div>
                      {{ item.position }} at {{ item.nameOrg }},
                      {{ item.city }} {{ item.state }}
                    </div>
                    <div>
                      Area of Specialization:
                      {{ JSON.parse(item.expertise || "[]").join(", ") }}
                      <br />
                      Years of Experience: {{ item.yearsOfExp }}
                    </div>
                  </div>

                  <!-- Status Label -->
                  <div
                    v-if="item.status === '60'"
                    class="text-align-center bold-text"
                  >
                    This Practitioner accepted the request.
                  </div>
                </div>
                <div v-else class="text-danger">Invalid Practitioner Data</div>
              </div>
            </template>
          </DataView>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { showToast, websiteURL } from "@/utils";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import RecommendationGalleryItem from "@/components/instructor/RecommendationGalleryItem.vue";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import { useCourseStore } from "@/stores/CourseStore";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useFeedStore } from "@/stores/FeedStore";
import { useRouter } from "vue-router";

const CourseStore = useCourseStore();
const RecommendationsStore = useRecommendationsStore();
const AuthStore = useAuthStore();
const FeedStore = useFeedStore();
const router = useRouter();

let loading = ref(false);
let message = ref("");
let successful = ref(false);
const currentUser = AuthStore.auth;

const course = CourseStore.course;

RecommendationsStore.setRecommendations(course);

const viewIndustryPractitioner = (course, practitioner) => {
  console.log("viewIndustryPractitioner");
  CourseStore.addCourse(course);
  RecommendationsStore.setPractitioner(practitioner.id);
  router.push({ name: "view-industry-practitioner-ins" });
};
</script>

<style scoped>
.recommendation-list:nth-child(odd) {
  background-color: rgba(15, 20, 25, 0.1);
}

.recommendation-img-card {
  width: 6rem;
  height: 6rem;
  margin: 0 auto 10px;
  display: block;
}

.number {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 50%;
  color: black;
  font-weight: bold;
  flex: none;
}

.priority {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: none;
}

.layout-button >>> .p-highlight {
  background: #0d6efd !important;
  border-color: #0d6efd !important;
}

div.gallery {
  min-height: 10rem;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: #dfe7ef;
  background-color: #fff;
  display: flex;
  text-align: center;
  align-items: center;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.image-container {
  overflow: hidden;
  flex: none;
  margin-left: 2rem;
  margin-right: 2rem;
}

div.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.disabled {
  pointer-events: none;
  opacity: 0.75;
}

@media only screen and (max-width: 600px) {
  .layout-button {
    display: none;
  }
}
</style>
