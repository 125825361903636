import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, mergeProps as _mergeProps, withCtx as _withCtx, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, isRef as _isRef, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { class: "mt-3 min-height" }
const _hoisted_3 = { class: "back-grid" }
const _hoisted_4 = {
  key: 0,
  class: "text-align-center bold-text",
  style: {"overflow-wrap":"anywhere"}
}
const _hoisted_5 = {
  key: 1,
  class: "text-align-center bold-text"
}
const _hoisted_6 = { class: "banner" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "card card-container mb-5" }
const _hoisted_10 = {
  key: 0,
  class: "bold-text"
}
const _hoisted_11 = {
  key: 0,
  class: "form-group"
}
const _hoisted_12 = {
  key: 1,
  class: "form-group"
}
const _hoisted_13 = {
  key: 2,
  class: "form-group"
}
const _hoisted_14 = ["checked"]
const _hoisted_15 = {
  key: 3,
  class: "form-group flex justify-space-between flex-direction-column mt-3 lg:flex-row"
}
const _hoisted_16 = { class: "ml-3" }
const _hoisted_17 = {
  key: 0,
  class: "font-color-banner"
}
const _hoisted_18 = {
  key: 1,
  class: "font-color-banner"
}
const _hoisted_19 = {
  key: 2,
  class: "font-color-banner"
}
const _hoisted_20 = { key: 3 }
const _hoisted_21 = {
  key: 4,
  class: "form-group bold-text ml-0 text-center lg:ml-5 lg:text-left"
}
const _hoisted_22 = {
  key: 5,
  class: "form-group flex justify-space-between flex-direction-column lg:flex-row"
}
const _hoisted_23 = { class: "ml-3" }
const _hoisted_24 = {
  key: 6,
  class: "form-group"
}
const _hoisted_25 = {
  key: 7,
  class: "form-group"
}
const _hoisted_26 = { class: "form-group text-align-center" }
const _hoisted_27 = ["disabled"]
const _hoisted_28 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Multiselect from "@vueform/multiselect";
import PractitionerService from "@/services/practitioner.service.js";
import {
  univOptions,
  displayLabels,
  showErrorToast,
  showToast,
  validateOptions,
  checkIfFilesAreTooBig,
} from "@/utils";
import { useRouter } from "vue-router";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import heic2any from "heic2any";

const MAX_TEXT_MAX_LENGTH = 2000;
const DIS_TEXT_MAX_LENGTH = 100;


export default /*@__PURE__*/_defineComponent({
  __name: 'UploadView',
  setup(__props) {

const router = useRouter();
const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
const uploadFields = ConfigStore.config.filter((el) => el.table === "upload");

const onInputWithNotification = (maxLength, event) => {
  const value = event.target.value;
  if (value.length > maxLength) {
    event.target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit reached`);
  }
};

// Enhanced URL validation regex
const urlRegex =
  /^(https?:\/\/|www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([/?#][^\s]*)?$/i;

let allowedFileType = ref("image/*");
const univOptionsAdded = ["All", ...univOptions];
let files = ref([] as File[]);
let convertedFiles = ref([] as File[]);
let loading = ref(false);
let message = ref("");

const schema = yup.object().shape({
  fields: yup.array().default(uploadFields),
  contentType: yup
    .string()
    .label("Content Type")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "contentType")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  description: yup
    .string()
    .label("Description")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "description")?.disabled,
      then: (schema) =>
        schema
          .required()
          .max(
            DIS_TEXT_MAX_LENGTH,
            `Description cannot be more than ${DIS_TEXT_MAX_LENGTH} characters!`
          ),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  visibility: yup
    .array()
    .of(yup.string())
    .label("Content Visibility Reach")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "visibility")?.disabled,
      then: (schema) =>
        schema.required().min(1, "Content Visibility Reach is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  rightToUpload: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "rightToUpload")?.disabled,
    then: (schema) =>
      schema
        .required("This is required to proceed!")
        .oneOf(["True"], "This is required to proceed!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  personalInfo: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "personalInfo")?.disabled,
    then: (schema) =>
      schema
        .required("This is required to proceed!")
        .oneOf(["True"], "This is required to proceed!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  file: yup.mixed().when("linkResources", {
    is: (val) => val.length > 0,
    then: (schema) => schema.optional(),
    otherwise: (schema) =>
      schema.required().test(
        "is-file-too-large",
        () => `File size exceeds the maximum allowable of 25MB!`,
        (value, testContext) =>
          checkIfFilesAreTooBig(value, testContext, files.value)
      ),
  }),
  linkResources: yup.string().when("personalInfo", {
    is: () => files && files.value.length > 0,
    then: (schema) => schema.optional().nullable(),
    otherwise: (schema) =>
      schema
        .test("is-valid-url", "Please enter a valid website URL", (value) => {
          if (!value) return true; // Skip validation if the field is empty
          return urlRegex.test(value); // Test the value against the regex
        })
        .required("Link to Resources or uploaded file is required!")
        .max(
          MAX_TEXT_MAX_LENGTH,
          `Website URL must be at most ${MAX_TEXT_MAX_LENGTH} characters`
        ),
  }),
});

const fileType = (e) => {
  if (e === "Video")
    allowedFileType.value =
      ".ogm, .wmv, .mpg, .webm, .ogv, .mov, .asx, .mpeg, .mp4, .m4v, .avi";
  else if (e === "Project Pictures")
    allowedFileType.value =
      ".xbm, .tif, .pjp, .apng, .jpg, .jpeg, .ico, .tiff, .gif, .svg, .jfif, .webp, .png, .bmp, .avif, .heic";
  else if (e === "Document")
    allowedFileType.value =
      ".pdf,.doc,.docx,application/msword,.txt,.ppt,.pptx,application/vnd.ms-powerpoint";
  files.value.length = 0;
};

let initialState = {
  contentType: "",
  description: "",
  visibility: [],
  rightToUpload: "",
  personalInfo: "",
  linkResources: "",
};

const convertImg = async (blob) => {
  return (async () => {
    const conversionResult = await heic2any({ blob });
    convertedFiles.value.push(
      new File(
        [conversionResult],
        blob.name.split(".").slice(0, -1).join(".") + "-conv.png"
      )
    );
  })();
};

const convertImages = async (files) => {
  for (let index = 0; index < files.length; index++) {
    if (!files[index].type.startsWith("image")) await convertImg(files[index]);
  }
  await console.log("done convertImages");
};

const uploadData = async (data) => {
  data["files"] = files.value;
  data["convertedFiles"] = convertedFiles.value;
  data["visibility"] = JSON.stringify(data["visibility"]);
  PractitionerService.uploadData(data).then(
    () => {
      showToast("Content Uploaded Successfully!");
      router.push({ name: "industry-practitioner-feed" });
      loading.value = false;
    },
    (error) => {
      console.log(error);
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(
        "Upload could not be completed, file size exceeds the maximum allowable of 25MB."
      );
    }
  );
};

const onValidSubmit = async (data) => {
  if (files && files.value.length > 0 && data["linkResources"].length > 0) {
    showErrorToast(
      "Please provide only one of the following : File or Hyperlink to resource."
    );
  } else {
    loading.value = true;
    if (data.contentType === "Project Pictures") {
      await convertImages(files.value);
    }
    await uploadData(data);
  }
};

const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};
const validateOptAll = (e, s) => {
  validateOptions(e, s, "All");
};

return (_ctx: any,_cache: any) => {
  const _component_va_file_upload = _resolveComponent("va-file-upload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(AppSidebar),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(BackButton),
        (_unref(currentUser).roles === 'INSTRUCTOR')
          ? (_openBlock(), _createElementBlock("h2", _hoisted_4, " Upload Project Videos/Images/Documents and other Instructional Resources "))
          : (_openBlock(), _createElementBlock("h2", _hoisted_5, " Upload Project Videos/Images/Documents "))
      ]),
      _createElementVNode("div", _hoisted_6, [
        (_unref(currentUser).roles === 'INSTRUCTOR')
          ? (_openBlock(), _createElementBlock("h5", _hoisted_7, " You can upload resources (images, videos, and documents) of construction projects or other instructional materials that can be useful to other instructors (for academic purposes only). "))
          : (_openBlock(), _createElementBlock("h5", _hoisted_8, " You can also support instructors by providing images, videos and documents of construction projects that can be used as elements of instructional materials (for academic purposes only). "))
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_unref(currentUser).roles !== 'INSTRUCTOR')
          ? (_openBlock(), _createElementBlock("h5", _hoisted_10, " All materials you upload will be used only for academic purposes "))
          : _createCommentVNode("", true),
        _cache[18] || (_cache[18] = _createElementVNode("span", { class: "font-color-red" }, "* required field", -1)),
        _createVNode(_unref(Form), {
          onSubmit: onValidSubmit,
          onInvalidSubmit: onInvalidSubmit,
          "validation-schema": _unref(schema),
          "initial-values": _unref(initialState),
          enctype: "multipart/form-data"
        }, {
          default: _withCtx(({ values }) => [
            (
              !_unref(uploadFields).find((el) => el.name === 'contentType')?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", { for: "contentType" }, [
                    _createTextVNode("Content Type "),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    name: "contentType",
                    type: "text",
                    class: "dropdown",
                    onChange: fileType,
                    autofocus: ""
                  }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(Multiselect), _mergeProps(field, {
                        modelValue: values.contentType,
                        "onUpdate:modelValue": ($event: any) => ((values.contentType) = $event),
                        options: ['Project Pictures', 'Video', 'Document'],
                        mode: "single",
                        searchable: true
                      }), null, 16, ["modelValue", "onUpdate:modelValue"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_unref(ErrorMessage), {
                    name: "contentType",
                    class: "error-feedback"
                  })
                ]))
              : _createCommentVNode("", true),
            (
              !_unref(uploadFields).find((el) => el.name === 'description')?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", { for: "description" }, [
                    _createTextVNode("Content Description "),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), { name: "description" }, {
                    default: _withCtx(({ field }) => [
                      _createElementVNode("input", _mergeProps(field, {
                        class: "form-control",
                        type: "text",
                        maxlength: DIS_TEXT_MAX_LENGTH,
                        onInput: _cache[0] || (_cache[0] = ($event: any) => (onInputWithNotification(DIS_TEXT_MAX_LENGTH, $event)))
                      }), null, 16),
                      _createTextVNode(" " + _toDisplayString(values.description ? values.description.length : 0) + " / " + _toDisplayString(DIS_TEXT_MAX_LENGTH) + " characters ", 1)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_unref(ErrorMessage), {
                    name: "description",
                    class: "error-feedback"
                  })
                ]))
              : _createCommentVNode("", true),
            (
              !_unref(uploadFields).find((el) => el.name === 'visibility')?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _cache[5] || (_cache[5] = _createElementVNode("label", { for: "visibility" }, [
                    _createTextVNode("Content Visibility Reach"),
                    _createElementVNode("span", { class: "super font-color-red" }, "*")
                  ], -1)),
                  _createVNode(_unref(Field), {
                    modelValue: values.visibility,
                    "onUpdate:modelValue": ($event: any) => ((values.visibility) = $event),
                    name: "visibility",
                    type: "text",
                    class: "form-control"
                  }, {
                    default: _withCtx(({ field }) => [
                      _createVNode(_unref(Multiselect), _mergeProps(field, {
                        modelValue: values.visibility,
                        "onUpdate:modelValue": ($event: any) => ((values.visibility) = $event),
                        options: univOptionsAdded,
                        mode: "tags",
                        searchable: true,
                        multipleLabel: _unref(displayLabels),
                        hideSelected: false,
                        closeOnSelect: false,
                        closeOnDeselect: false,
                        allowAbsent: true,
                        createOption: true,
                        onChange: validateOptAll
                      }), {
                        option: _withCtx(({ option }) => [
                          _createElementVNode("input", {
                            type: "checkbox",
                            class: "input-pointer",
                            checked: values.visibility.includes(option.value)
                          }, null, 8, _hoisted_14),
                          _createTextVNode("   " + _toDisplayString(option.label), 1)
                        ]),
                        _: 2
                      }, 1040, ["modelValue", "onUpdate:modelValue", "multipleLabel"])
                    ]),
                    _: 2
                  }, 1032, ["modelValue", "onUpdate:modelValue"]),
                  _createVNode(_unref(ErrorMessage), {
                    name: "visibility",
                    class: "error-feedback"
                  })
                ]))
              : _createCommentVNode("", true),
            (!_unref(uploadFields).find((el) => el.name === 'files')?.disabled)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "flex-grow-0 flex-shrink-1" }, [
                    _createElementVNode("label", { style: {"margin-top":"0"} }, "Upload File(s)")
                  ], -1)),
                  _createElementVNode("div", _hoisted_16, [
                    _createVNode(_unref(Field), { name: "file" }, {
                      default: _withCtx(({ handleChange, handleBlur }) => [
                        _createVNode(_component_va_file_upload, {
                          modelValue: _unref(files),
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(files) ? (files).value = $event : files = $event)),
                          dropzone: "",
                          onChange: handleChange,
                          onBlur: handleBlur,
                          uploadButtonText: "Choose File",
                          "file-types": _unref(allowedFileType)
                        }, null, 8, ["modelValue", "onChange", "onBlur", "file-types"]),
                        _createVNode(_unref(ErrorMessage), {
                          name: "file",
                          class: "error-feedback"
                        })
                      ]),
                      _: 1
                    }),
                    _cache[7] || (_cache[7] = _createElementVNode("div", { class: "font-color-banner mt-0" }, " Maximum allowable file size is 25MB ", -1)),
                    (values.contentType === 'Project Pictures')
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, " Allowed File Type: .xbm, .tif, .pjp, .apng, .jpg, .jpeg, .ico, .tiff, .gif, .svg, .jfif, .webp, .png, .bmp, .avif, .heic "))
                      : (values.contentType === 'Video')
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, " Allowed File Type: .ogm, .wmv, .mpg, .webm, .ogv, .mov, .asx, .mpeg, .mp4, .m4v, .avi "))
                        : (values.contentType === 'Document')
                          ? (_openBlock(), _createElementBlock("div", _hoisted_19, " Allowed File Type: .pdf, .doc, .docx, .txt, .ppt, .pptx "))
                          : (_openBlock(), _createElementBlock("div", _hoisted_20, _cache[6] || (_cache[6] = [
                              _createElementVNode("br", null, null, -1)
                            ])))
                  ])
                ]))
              : _createCommentVNode("", true),
            (
              !_unref(uploadFields).find((el) => el.name === 'files')?.disabled &&
              !_unref(uploadFields).find((el) => el.name === 'linkResources')?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, " OR "))
              : _createCommentVNode("", true),
            (
              !_unref(uploadFields).find((el) => el.name === 'linkResources')?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "flex-grow-0 flex-shrink-1" }, [
                    _createElementVNode("label", { for: "linkResources" }, "Provide Link")
                  ], -1)),
                  _createElementVNode("div", _hoisted_23, [
                    _createVNode(_unref(Field), { name: "linkResources" }, {
                      default: _withCtx(({ field }) => [
                        _createElementVNode("input", _mergeProps(field, {
                          class: "form-control",
                          type: "text",
                          placeholder: "https://",
                          maxlength: MAX_TEXT_MAX_LENGTH,
                          onInput: _cache[2] || (_cache[2] = ($event: any) => (onInputWithNotification(MAX_TEXT_MAX_LENGTH, $event)))
                        }), null, 16),
                        _createTextVNode(" " + _toDisplayString(values.linkResources ? values.linkResources.length : 0) + " / " + _toDisplayString(MAX_TEXT_MAX_LENGTH) + " characters ", 1),
                        _createVNode(_unref(ErrorMessage), {
                          name: "linkResources",
                          class: "error-feedback"
                        })
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]))
              : _createCommentVNode("", true),
            (
              !_unref(uploadFields).find((el) => el.name === 'rightToUpload')?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createVNode(_unref(Field), {
                    name: "rightToUpload",
                    value: "True",
                    type: "checkbox"
                  }),
                  _cache[10] || (_cache[10] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                  _cache[11] || (_cache[11] = _createTextVNode(" I confirm that I have the right to upload this content and share it with instructors to enhance their instructional materials. ")),
                  _cache[12] || (_cache[12] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_unref(ErrorMessage), {
                    name: "rightToUpload",
                    class: "error-feedback"
                  })
                ]))
              : _createCommentVNode("", true),
            (
              !_unref(uploadFields).find((el) => el.name === 'personalInfo')?.disabled
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                  _createVNode(_unref(Field), {
                    name: "personalInfo",
                    value: "True",
                    type: "checkbox"
                  }),
                  _cache[13] || (_cache[13] = _createElementVNode("span", { class: "super font-color-red" }, "*", -1)),
                  _cache[14] || (_cache[14] = _createTextVNode(" I confirm that this material is void of any personal identifiable information or privacy data. ")),
                  _cache[15] || (_cache[15] = _createElementVNode("br", null, null, -1)),
                  _createVNode(_unref(ErrorMessage), {
                    name: "personalInfo",
                    class: "error-feedback"
                  })
                ]))
              : _createCommentVNode("", true),
            _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("button", {
                type: "submit",
                class: "btn btn-primary btn-block",
                disabled: _unref(loading)
              }, [
                _withDirectives(_createElementVNode("span", _hoisted_28, null, 512), [
                  [_vShow, _unref(loading)]
                ]),
                _cache[16] || (_cache[16] = _createElementVNode("span", null, "Upload", -1))
              ], 8, _hoisted_27)
            ])
          ]),
          _: 1
        }, 8, ["validation-schema", "initial-values"])
      ])
    ])
  ]))
}
}

})