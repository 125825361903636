<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="mt-3 min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2
          v-if="currentUser.roles === 'INSTRUCTOR'"
          class="text-align-center bold-text"
          style="overflow-wrap: anywhere"
        >
          Upload Project Videos/Images/Documents and other Instructional
          Resources
        </h2>
        <h2 v-else class="text-align-center bold-text">
          Upload Project Videos/Images/Documents
        </h2>
      </div>
      <div class="banner">
        <h5 v-if="currentUser.roles === 'INSTRUCTOR'">
          You can upload resources (images, videos, and documents) of
          construction projects or other instructional materials that can be
          useful to other instructors (for academic purposes only).
        </h5>
        <h5 v-else>
          You can also support instructors by providing images, videos and
          documents of construction projects that can be used as elements of
          instructional materials (for academic purposes only).
        </h5>
      </div>
      <div class="card card-container mb-5">
        <h5 v-if="currentUser.roles !== 'INSTRUCTOR'" class="bold-text">
          All materials you upload will be used only for academic purposes
        </h5>
        <span class="font-color-red">* required field</span>
        <Form
          @submit="onValidSubmit"
          @invalid-submit="onInvalidSubmit"
          v-slot="{ values }"
          :validation-schema="schema"
          :initial-values="initialState"
          enctype="multipart/form-data"
        >
          <div
            class="form-group"
            v-if="
              !uploadFields.find((el) => el.name === 'contentType')?.disabled
            "
          >
            <label for="contentType"
              >Content Type <span class="super font-color-red">*</span></label
            >
            <Field
              name="contentType"
              type="text"
              class="dropdown"
              @change="fileType"
              v-slot="{ field }"
              autofocus
            >
              <Multiselect
                v-bind="field"
                v-model="values.contentType"
                :options="['Project Pictures', 'Video', 'Document']"
                mode="single"
                :searchable="true"
              />
            </Field>
            <ErrorMessage name="contentType" class="error-feedback" />
          </div>
          <div
            class="form-group"
            v-if="
              !uploadFields.find((el) => el.name === 'description')?.disabled
            "
          >
            <label for="description"
              >Content Description
              <span class="super font-color-red">*</span></label
            >
            <Field name="description" v-slot="{ field }">
              <input
                v-bind="field"
                class="form-control"
                type="text"
                :maxlength="DIS_TEXT_MAX_LENGTH"
                @input="onInputWithNotification(DIS_TEXT_MAX_LENGTH, $event)"
              />
              {{ values.description ? values.description.length : 0 }}
              / {{ DIS_TEXT_MAX_LENGTH }} characters
            </Field>
            <ErrorMessage name="description" class="error-feedback" />
          </div>
          <div
            class="form-group"
            v-if="
              !uploadFields.find((el) => el.name === 'visibility')?.disabled
            "
          >
            <label for="visibility"
              >Content Visibility Reach<span class="super font-color-red"
                >*</span
              ></label
            >
            <Field
              v-model="values.visibility"
              name="visibility"
              type="text"
              class="form-control"
              v-slot="{ field }"
            >
              <Multiselect
                v-bind="field"
                v-model="values.visibility"
                :options="univOptionsAdded"
                mode="tags"
                :searchable="true"
                :multipleLabel="displayLabels"
                :hideSelected="false"
                :closeOnSelect="false"
                :closeOnDeselect="false"
                :allowAbsent="true"
                :createOption="true"
                @change="validateOptAll"
              >
                <template v-slot:option="{ option }">
                  <input
                    type="checkbox"
                    class="input-pointer"
                    :checked="values.visibility.includes(option.value)"
                  />
                  &nbsp; {{ option.label }}
                </template>
              </Multiselect>
            </Field>
            <ErrorMessage name="visibility" class="error-feedback" />
          </div>
          <div
            class="form-group flex justify-space-between flex-direction-column mt-3 lg:flex-row"
            v-if="!uploadFields.find((el) => el.name === 'files')?.disabled"
          >
            <div class="flex-grow-0 flex-shrink-1">
              <label style="margin-top: 0">Upload File(s)</label>
            </div>
            <div class="ml-3">
              <Field name="file" v-slot="{ handleChange, handleBlur }">
                <va-file-upload
                  v-model="files"
                  dropzone
                  @change="handleChange"
                  @blur="handleBlur"
                  uploadButtonText="Choose File"
                  :file-types="allowedFileType"
                />
                <ErrorMessage name="file" class="error-feedback" />
              </Field>
              <div class="font-color-banner mt-0">
                Maximum allowable file size is 25MB
              </div>
              <div
                v-if="values.contentType === 'Project Pictures'"
                class="font-color-banner"
              >
                Allowed File Type: .xbm, .tif, .pjp, .apng, .jpg, .jpeg, .ico,
                .tiff, .gif, .svg, .jfif, .webp, .png, .bmp, .avif, .heic
              </div>
              <div
                v-else-if="values.contentType === 'Video'"
                class="font-color-banner"
              >
                Allowed File Type: .ogm, .wmv, .mpg, .webm, .ogv, .mov, .asx,
                .mpeg, .mp4, .m4v, .avi
              </div>
              <div
                v-else-if="values.contentType === 'Document'"
                class="font-color-banner"
              >
                Allowed File Type: .pdf, .doc, .docx, .txt, .ppt, .pptx
              </div>
              <div v-else><br /></div>
            </div>
          </div>
          <div
            class="form-group bold-text ml-0 text-center lg:ml-5 lg:text-left"
            v-if="
              !uploadFields.find((el) => el.name === 'files')?.disabled &&
              !uploadFields.find((el) => el.name === 'linkResources')?.disabled
            "
          >
            OR
          </div>
          <div
            class="form-group flex justify-space-between flex-direction-column lg:flex-row"
            v-if="
              !uploadFields.find((el) => el.name === 'linkResources')?.disabled
            "
          >
            <div class="flex-grow-0 flex-shrink-1">
              <label for="linkResources">Provide Link</label>
            </div>
            <div class="ml-3">
              <Field name="linkResources" v-slot="{ field }">
                <input
                  v-bind="field"
                  class="form-control"
                  type="text"
                  placeholder="https://"
                  :maxlength="MAX_TEXT_MAX_LENGTH"
                  @input="onInputWithNotification(MAX_TEXT_MAX_LENGTH, $event)"
                />
                {{ values.linkResources ? values.linkResources.length : 0 }}
                / {{ MAX_TEXT_MAX_LENGTH }} characters
                <ErrorMessage name="linkResources" class="error-feedback" />
              </Field>
            </div>
          </div>
          <div
            class="form-group"
            v-if="
              !uploadFields.find((el) => el.name === 'rightToUpload')?.disabled
            "
          >
            <Field name="rightToUpload" value="True" type="checkbox" /><span
              class="super font-color-red"
              >*</span
            >
            I confirm that I have the right to upload this content and share it
            with instructors to enhance their instructional materials. <br />
            <ErrorMessage name="rightToUpload" class="error-feedback" />
          </div>
          <div
            class="form-group"
            v-if="
              !uploadFields.find((el) => el.name === 'personalInfo')?.disabled
            "
          >
            <Field name="personalInfo" value="True" type="checkbox" /><span
              class="super font-color-red"
              >*</span
            >
            I confirm that this material is void of any personal identifiable
            information or privacy data. <br />
            <ErrorMessage name="personalInfo" class="error-feedback" />
          </div>
          <br />
          <div class="form-group text-align-center">
            <button
              type="submit"
              class="btn btn-primary btn-block"
              :disabled="loading"
            >
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              <span>Upload</span>
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import Multiselect from "@vueform/multiselect";
import PractitionerService from "@/services/practitioner.service.js";
import {
  univOptions,
  displayLabels,
  showErrorToast,
  showToast,
  validateOptions,
  checkIfFilesAreTooBig,
} from "@/utils";
import { useRouter } from "vue-router";
import { useConfigStore } from "@/stores/ConfigStore";
import { useAuthStore } from "@/stores/AuthStore";
import heic2any from "heic2any";

const router = useRouter();
const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
const uploadFields = ConfigStore.config.filter((el) => el.table === "upload");

const MAX_TEXT_MAX_LENGTH = 2000;
const DIS_TEXT_MAX_LENGTH = 100;

const onInputWithNotification = (maxLength, event) => {
  const value = event.target.value;
  if (value.length > maxLength) {
    event.target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit reached`);
  }
};

// Enhanced URL validation regex
const urlRegex =
  /^(https?:\/\/|www\.)?[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+([/?#][^\s]*)?$/i;

let allowedFileType = ref("image/*");
const univOptionsAdded = ["All", ...univOptions];
let files = ref([] as File[]);
let convertedFiles = ref([] as File[]);
let loading = ref(false);
let message = ref("");

const schema = yup.object().shape({
  fields: yup.array().default(uploadFields),
  contentType: yup
    .string()
    .label("Content Type")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "contentType")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  description: yup
    .string()
    .label("Description")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "description")?.disabled,
      then: (schema) =>
        schema
          .required()
          .max(
            DIS_TEXT_MAX_LENGTH,
            `Description cannot be more than ${DIS_TEXT_MAX_LENGTH} characters!`
          ),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  visibility: yup
    .array()
    .of(yup.string())
    .label("Content Visibility Reach")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "visibility")?.disabled,
      then: (schema) =>
        schema.required().min(1, "Content Visibility Reach is required!"),
      otherwise: (schema) => schema.optional().nullable().min(0),
    }),
  rightToUpload: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "rightToUpload")?.disabled,
    then: (schema) =>
      schema
        .required("This is required to proceed!")
        .oneOf(["True"], "This is required to proceed!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  personalInfo: yup.string().when("fields", {
    is: (val) => !val.find((el) => el.name === "personalInfo")?.disabled,
    then: (schema) =>
      schema
        .required("This is required to proceed!")
        .oneOf(["True"], "This is required to proceed!"),
    otherwise: (schema) => schema.optional().nullable(),
  }),
  file: yup.mixed().when("linkResources", {
    is: (val) => val.length > 0,
    then: (schema) => schema.optional(),
    otherwise: (schema) =>
      schema.required().test(
        "is-file-too-large",
        () => `File size exceeds the maximum allowable of 25MB!`,
        (value, testContext) =>
          checkIfFilesAreTooBig(value, testContext, files.value)
      ),
  }),
  linkResources: yup.string().when("personalInfo", {
    is: () => files && files.value.length > 0,
    then: (schema) => schema.optional().nullable(),
    otherwise: (schema) =>
      schema
        .test("is-valid-url", "Please enter a valid website URL", (value) => {
          if (!value) return true; // Skip validation if the field is empty
          return urlRegex.test(value); // Test the value against the regex
        })
        .required("Link to Resources or uploaded file is required!")
        .max(
          MAX_TEXT_MAX_LENGTH,
          `Website URL must be at most ${MAX_TEXT_MAX_LENGTH} characters`
        ),
  }),
});

const fileType = (e) => {
  if (e === "Video")
    allowedFileType.value =
      ".ogm, .wmv, .mpg, .webm, .ogv, .mov, .asx, .mpeg, .mp4, .m4v, .avi";
  else if (e === "Project Pictures")
    allowedFileType.value =
      ".xbm, .tif, .pjp, .apng, .jpg, .jpeg, .ico, .tiff, .gif, .svg, .jfif, .webp, .png, .bmp, .avif, .heic";
  else if (e === "Document")
    allowedFileType.value =
      ".pdf,.doc,.docx,application/msword,.txt,.ppt,.pptx,application/vnd.ms-powerpoint";
  files.value.length = 0;
};

let initialState = {
  contentType: "",
  description: "",
  visibility: [],
  rightToUpload: "",
  personalInfo: "",
  linkResources: "",
};

const convertImg = async (blob) => {
  return (async () => {
    const conversionResult = await heic2any({ blob });
    convertedFiles.value.push(
      new File(
        [conversionResult],
        blob.name.split(".").slice(0, -1).join(".") + "-conv.png"
      )
    );
  })();
};

const convertImages = async (files) => {
  for (let index = 0; index < files.length; index++) {
    if (!files[index].type.startsWith("image")) await convertImg(files[index]);
  }
  await console.log("done convertImages");
};

const uploadData = async (data) => {
  data["files"] = files.value;
  data["convertedFiles"] = convertedFiles.value;
  data["visibility"] = JSON.stringify(data["visibility"]);
  PractitionerService.uploadData(data).then(
    () => {
      showToast("Content Uploaded Successfully!");
      router.push({ name: "industry-practitioner-feed" });
      loading.value = false;
    },
    (error) => {
      console.log(error);
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      showErrorToast(
        "Upload could not be completed, file size exceeds the maximum allowable of 25MB."
      );
    }
  );
};

const onValidSubmit = async (data) => {
  if (files && files.value.length > 0 && data["linkResources"].length > 0) {
    showErrorToast(
      "Please provide only one of the following : File or Hyperlink to resource."
    );
  } else {
    loading.value = true;
    if (data.contentType === "Project Pictures") {
      await convertImages(files.value);
    }
    await uploadData(data);
  }
};

const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};
const validateOptAll = (e, s) => {
  validateOptions(e, s, "All");
};
</script>

<style scoped>
.card-container.card {
  max-width: 80% !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 50px auto 25px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.banner h5 {
  text-align: center;
  padding: 0em 8em;
  margin-top: 1rem;
}

@media screen and (max-width: 768px) {
  .card {
    margin-top: 10px;
  }
  .card-container.card {
    max-width: 90% !important;
    padding: 10px 10px;
  }
  .banner h5 {
    padding: 0em 0.5em;
    margin-top: 0.5rem;
  }
}
</style>
