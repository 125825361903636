<template>
  <div class="grid-container">
    <div v-if="currentUser.roles === 'INDUSTRYPRACTITIONER'">
      <practitioner-sidebar></practitioner-sidebar>
    </div>
    <div v-else-if="currentUser.roles === 'INSTRUCTOR'">
      <instructor-sidebar></instructor-sidebar>
    </div>
    <div class="container min-height">
      <div class="back-grid">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          <span v-if="route.params.role === 'industrypractitioner'">
            Industry Practitioners
          </span>
          <span v-else>Instructors</span>
        </h2>
      </div>
      <br />
      <DataView :value="userList" layout="grid">
        <template #grid="slotProps">
          <div class="data-view-container">
            <div
              v-for="(item, index) in slotProps.items"
              :key="item.id || index"
              class="grid-item"
            >
              <router-link
                :to="{
                  name: 'user-profile',
                  params: { userId: item.id },
                }"
              >
                <div
                  class="user-card border-1 surface-border surface-card border-round cursor-pointer"
                >
                  <!-- User Profile Image -->
                  <img
                    v-if="item.fileName"
                    id="profile-img"
                    :src="websiteURL + 'profile_picture/' + item.fileName"
                    class="recommendation-img-card"
                  />
                  <img
                    v-else
                    id="profile-img"
                    src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                    class="recommendation-img-card"
                  />
                  <!-- User Details -->
                  <div class="user-details">
                    <b class="user-name"
                      >{{ item.firstName }} {{ item.lastName }}</b
                    >
                    <p class="user-position">
                      {{ item.position }} at {{ item.nameOrg }}
                    </p>
                    <p class="user-location">
                      {{ item.city }}, {{ item.state }}
                    </p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </template>
      </DataView>
    </div>
  </div>
</template>

<script setup lang="ts">
import UserService from "@/services/user.service.js";
import { ref, watch } from "vue";
import { websiteURL } from "@/utils";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import DataView from "primevue/dataview";

const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
const route = useRoute();
const userList = ref([]);

const getUserList = (role) => {
  UserService.getUsers(role).then(
    (response) => {
      userList.value = response.data;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.items.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

watch(
  () => route.params.role,
  (role) => {
    getUserList(role as string);
  },
  { immediate: true }
);
</script>

<style scoped>
.data-view-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(200px, 1fr)
  ); /* Ensures proper column widths */
  gap: 1rem; /* Adds spacing between items */
  padding: 1%;
}

.grid-item {
  display: flex;
  justify-content: center; /* Center the user cards */
}

.user-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  background-color: #fff;
  transition: box-shadow 0.3s ease;
}

.user-card:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle hover effect */
}

.recommendation-img-card {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover; /* Ensures the image maintains its aspect ratio */
  margin-bottom: 1rem;
  border: 2px solid #ddd; /* Optional: Add border for better visual definition */
}

.user-details {
  font-size: 0.9rem;
  color: #555;
}

.user-name {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.user-position {
  margin: 0.5rem 0;
  font-style: italic;
}

.user-location {
  color: #777;
}
</style>
