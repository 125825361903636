<template>
  <div class="sidebar sidebar-max min-height">
    <profile-picture></profile-picture>
    <div style="text-align: center">
      <span class="bold-text">
        {{ currentUser.firstName + " " + currentUser.lastName }} </span
      ><br />
      <span style="font-weight: 300">
        {{ currentUser.title }}<br />
        <span v-if="currentUser.profile">
          {{ currentUser.profile.department }} <br />
        </span>
      </span>
      <i>
        <span v-if="currentUser.nameOrg">
          {{ currentUser.nameOrg }} <br />
        </span>
        <span v-if="currentUser.profile">
          {{ currentUser.profile.city + " " + currentUser.profile.state }}
        </span>
      </i>

      <br /><br />
      <router-link
        :to="{
          name: 'my-user-profile',
          params: { userId: currentUser.id },
        }"
      >
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path.startsWith('/profile')
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="View/edit your profile information."
        >
          <img src="@/assets/images/profile.png" class="sidebar-image-large" />
          <span class="ml-3">Profile</span>
        </div>
      </router-link>
      <router-link
        :to="{
          name: 'instructor-classes',
        }"
      >
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path == '/instructor/classes'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="Add and edit the classes (courses) you are teaching."
        >
          <img src="@/assets/images/class.png" class="sidebar-image-large" />
          <span class="ml-2">My Classes</span>
        </div>
      </router-link>
      <div
        class="sidebar-button pl-3"
        @click="raiseCourseRequest"
        :class="[
          route.path.startsWith('/instructor/course')
            ? 'selected-sidebar-button'
            : 'unselected-sidebar-button',
        ]"
        title="Place a request for course-support to see recommended practitioners."
      >
        <img
          src="@/assets/images/course_support.png"
          class="sidebar-image-large"
        />
        <span class="ml-2">Request Course-Support</span>
      </div>
      <router-link :to="{ name: 'new-resources' }">
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path === '/instructor/resources/new'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="Place a request for instructional pictures, videos, and documents."
        >
          <img
            src="@/assets/images/resources.png"
            class="sidebar-image-large"
          />
          <span class="ml-2">Request Resources</span>
        </div>
      </router-link>
      <router-link :to="{ name: 'upload' }">
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path === '/upload'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="Share instructional pictures, videos, and documents with other instructors."
        >
          <img
            src="@/assets/images/upload_resources.png"
            class="sidebar-image-large"
            alt="Upload Resources"
          />
          <span class="ml-3">Upload Resources</span>
        </div>
      </router-link>
      <router-link :to="{ name: 'instructor-view-resources' }" class="nav-link">
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path === '/instructor/resources/view'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="Access instructional pictures, videos, and documents shared by other users."
        >
          <img
            src="@/assets/images/view_resources.png"
            class="sidebar-image-large"
          />
          <span class="ml-3">View Resources</span>
        </div>
      </router-link>
      <router-link :to="{ name: 'view-notifications-ins' }">
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path === '/instructor/notifications'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="Your notifications will appear here."
        >
          <font-awesome-icon
            class="sidebar-icon"
            icon="fa-regular fa-bell"
            style="color: #fcc62a"
          />
          <span style="position: relative">
            <span class="notification-count">{{
              NotificationStore.count
            }}</span>
          </span>
          <span class="ml-3">View Notifications</span>
        </div>
      </router-link>
      <router-link :to="{ name: 'feedback' }" class="nav-link">
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path === '/feedback'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="Provide feedback to the Admin here."
        >
          <img src="@/assets/images/feedback.png" class="sidebar-image-large" />
          <span class="ml-3">Feedback</span>
        </div>
      </router-link>
      <div
        class="sidebar-button pl-3"
        :class="[
          isVisible ? 'selected-sidebar-button' : 'unselected-sidebar-button',
        ]"
        @click="showInviteOthers"
        title="Invite others to ConPEC here."
      >
        <img
          src="@/assets/images/invite_users.png"
          class="sidebar-image-large"
        />
        <span class="ml-3">Invite Potential Users</span>
      </div>
      <router-link :to="{ name: 'guide' }" class="nav-link">
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path === '/guide/'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="Help/Guide"
        >
          <img src="@/assets/images/guide.png" class="sidebar-image-large" />
          <span class="ml-3">Help/Guide</span>
        </div>
      </router-link>
      <router-link
        :to="{ name: 'settings' }"
        class="nav-link"
        v-if="
          !settingsFields.find((el) => el.name === 'email')?.disabled ||
          !settingsFields.find((el) => el.name === 'reasonDelete')?.disabled
        "
      >
        <div
          class="sidebar-button pl-3"
          :class="[
            route.path === '/settings/'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="Settings"
        >
          <img src="@/assets/images/settings.png" class="sidebar-image-large" />
          <span class="ml-3">Settings</span>
        </div>
      </router-link>
    </div>
  </div>
  <div class="sidebar sidebar-min min-height">
    <profile-icon></profile-icon>
    <div>
      <div class="mt-3">
        <router-link
          :to="{
            name: 'my-user-profile',
            params: { userId: currentUser.id },
          }"
        >
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path.startsWith('/profile')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="View/edit your profile information."
          >
            <img
              src="@/assets/images/profile.png"
              class="sidebar-image-large"
            />
          </div>
        </router-link>
        <router-link
          :to="{
            name: 'instructor-classes',
          }"
        >
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path == '/instructor/classes'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Add and edit the classes (courses) you are teaching."
          >
            <img src="@/assets/images/class.png" class="sidebar-image-large" />
          </div>
        </router-link>
        <div
          class="sidebar-button pl-3"
          @click="raiseCourseRequest"
          :class="[
            route.path.startsWith('/instructor/course')
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]"
          title="Place a request for course-support to see recommended practitioners."
        >
          <img
            src="@/assets/images/course_support.png"
            class="sidebar-image-large"
          />
        </div>
        <router-link :to="{ name: 'new-resources' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/instructor/resources/new'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Place a request for instructional pictures, videos, and documents."
          >
            <img
              src="@/assets/images/resources.png"
              class="sidebar-image-large"
            />
          </div>
        </router-link>
        <router-link :to="{ name: 'upload' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path.startsWith('/upload')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Share instructional pictures, videos, and documents with other instructors."
          >
            <img
              src="@/assets/images/upload_resources.png"
              class="sidebar-image-large"
              alt="Upload Resources"
            />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'instructor-view-resources' }"
          class="nav-link"
        >
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/instructor/uploads'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Access instructional pictures, videos, and documents shared by other users."
          >
            <img
              src="@/assets/images/view_resources.png"
              class="sidebar-image-large"
            />
          </div>
        </router-link>
        <router-link :to="{ name: 'view-notifications-ins' }">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/instructor/notifications'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Your notifications will appear here."
          >
            <font-awesome-icon
              class="sidebar-icon"
              icon="fa-regular fa-bell"
              style="color: #fcc62a"
            />
            <span style="position: relative">
              <span class="notification-count">{{
                NotificationStore.count
              }}</span>
            </span>
          </div>
        </router-link>
        <router-link :to="{ name: 'feedback' }" class="nav-link">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/feedback'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Provide feedback to the Admin here."
          >
            <img
              src="@/assets/images/feedback.png"
              class="sidebar-image-large"
            />
          </div>
        </router-link>
        <div
          class="sidebar-button pl-3"
          :class="[
            isVisible ? 'selected-sidebar-button' : 'unselected-sidebar-button',
          ]"
          @click="showInviteOthers"
          title="Invite others to ConPEC here."
        >
          <img
            src="@/assets/images/invite_users.png"
            class="sidebar-image-large"
          />
        </div>
        <router-link :to="{ name: 'guide' }" class="nav-link">
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/guide/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Help/Guide"
          >
            <img src="@/assets/images/guide.png" class="sidebar-image-large" />
          </div>
        </router-link>
        <router-link
          :to="{ name: 'settings' }"
          class="nav-link"
          v-if="
            !settingsFields.find((el) => el.name === 'email')?.disabled ||
            !settingsFields.find((el) => el.name === 'reasonDelete')?.disabled
          "
        >
          <div
            class="sidebar-button pl-3"
            :class="[
              route.path === '/settings/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]"
            title="Settings"
          >
            <img
              src="@/assets/images/settings.png"
              class="sidebar-image-large"
            />
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="isVisible"
    modal
    header="Invite people from your network"
    :style="{ width: '50vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <invite-users-form @hideDialog="isVisible = false" />
  </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import ProfilePicture from "@/components/user/ProfilePicture.vue";
import ProfileIcon from "@/components/user/ProfileIcon.vue";
import Dialog from "primevue/dialog";
import InviteUsersForm from "@/components/user/InviteUsersForm.vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useNotificationStore } from "@/stores/NotificationsStore";
import { useRouter, useRoute } from "vue-router";
import { showErrorToast } from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const NotificationStore = useNotificationStore();
const router = useRouter();
const route = useRoute();

const settingsFields = ConfigStore.config.filter(
  (el) => el.table === "settings"
);

let currentUser = AuthStore.auth;
let isVisible = ref(false);
let loading = ref(false);
let message = ref("");

const raiseCourseRequest = () => {
  CourseStore.removeCourse();
  router.push({ name: "course-request" });
};

const showInviteOthers = () => {
  if (AuthStore.profile) isVisible.value = true;
  else
    showErrorToast(
      "Please complete your profile before proceeding to any part of the web platform"
    );
};

NotificationStore.getNotification();
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>
