<template>
  <div class="m-2 lg:m-5 lg:ml-0 min-height p-7 faq-container">
    <back-button></back-button>
    <div class="flex flex-col items-center mt-4">
      <img src="@/assets/images/site/faq.png" class="image-large" title="FAQ" />
    </div>
    <div class="mt-2 flex flex-row p-4">
      <div>
        <h1>Frequently Asked Questions</h1>
        <div class="mt-3">
          <va-collapse
            v-model="values[0]"
            class="w-96 my-3"
            header="What is ConPEC?"
          >
            <div class="answer">
              ConPEC is a collaborative networking platform for instructors and
              practitioners to collaborate for future workforce development. The
              web-based platform provides instructors with easy access to
              diverse pool of practitioners who are willing and able to support
              their pedagogical efforts.
            </div>
          </va-collapse>
          <va-collapse
            v-model="values[1]"
            class="w-96 my-3"
            header="Who are the people behind or funding ConPEC?"
          >
            <div class="answer">
              ConPEC is being funded via a National Science Foundation grant
              targeted at intelligently preparing the future construction
              engineering workforce for the workplace by connecting the
              professional and educational communities. The research is being
              conducted by Virginia Tech and North Carolina Agricultural and
              Technical State University.
            </div>
          </va-collapse>
          <va-collapse
            v-model="values[2]"
            class="w-96 my-3"
            header="Who is ConPEC designed for?"
          >
            <div class="answer">
              ConPEC is designed for instructors in construction related
              programs and construction industry practitioners. The
              construction-related programs include Building Construction, Civil
              and Environmental Engineering, Construction Management,
              Construction Engineering and Management and other similar academic
              programs. These two categories of users are the only users
              expected to use the platform.
            </div>
          </va-collapse>
          <va-collapse
            v-model="values[3]"
            class="w-96 my-3"
            header="Who are the instructors on ConPEC?"
          >
            <div class="answer">
              Instructors are professors and faculty members in different
              construction-related academic programs.
            </div>
          </va-collapse>
          <va-collapse
            v-model="values[4]"
            class="w-96 my-3"
            header="Who are practitioners on ConPEC?"
          >
            <div class="answer">
              Practitioners are construction professionals, construction workers
              and allied professionals.
            </div>
          </va-collapse>
          <va-collapse
            v-model="values[5]"
            class="w-96 my-3"
            header="Why can’t I use my Gmail or Yahoo mail address to sign up?"
          >
            <div class="answer">
              As a way of ensuring that the people who use the platform are the
              actual intended users, we require instructors to use their
              institutional email addresses and practitioners to use their
              work/company email addresses. The following email domains are not
              permitted: "gmail.com", "yahoo.com", "outlook.com", "hotmail.com",
              "aol.com", 'hotmail.co.uk', "hotmail.fr", "msn.com",
              "comcast.net", "live.com", "verizon.net", “mail.com”,
              “protonmail.com”, “zoho.com”, “icloud.com”, “me.com”, “mac.com”,
              “gmx.com”, “yandex.com”, “btinternet.com”, “orange.fr”,
              “tiscali.co.uk”, “fastmail.com”, “hushmail.com”, “tutanota.com”,
              “tempmail.com”, “mailinator.com”, “runbox.com”, “compuserve.com”,
              “lycos.com”, “excite.com”, “netscape.net”, “rocketmail.com”,
              “inbox.com”, “post.com”, “t-online.de”, “sky.com”,
              “virginmedia.com”, “bellsouth.net”, “charter.net”, “att.net”,
              “yopmail.com”, “throwawaymail.com”, “temp-mail.org”,
              “10minutemail.com”, “guerrillamail.com”, “startmail.com”,
              “countermail.com”, “xtra.co.nz”, “bigpond.com”, “naver.com”,
              “163.com”, “qq.com”, “hanmail.net”, “daum.net”, “web.de”,
              “laposte.net”, “freenet.de”, “o2.co.uk”, “sify.com”,
              “rediffmail.com”, “mail.ru”, “bigfoot.com”, “seznam.cz”, “web.de”.
            </div>
          </va-collapse>
          <va-collapse
            v-model="values[6]"
            class="w-96 my-3"
            header="What is ConPEC designed to achieve?"
          >
            <div class="answer">
              ConPEC is ultimately designed for future workforce development.
              This is achieved by providing instructors equitable access to
              practitioners willing to meet instructors’ course-support needs.
              The connection between instructors and practitioners would ensure
              that students interact more with their communities of practice,
              which could help discipline their perception and develop their
              professional identities. This could also help ensure an adequate
              blend of theory and practice and develop the necessary skills and
              competencies which are industry requirements.
            </div>
          </va-collapse>
          <va-collapse
            v-model="values[7]"
            class="w-96 my-3"
            header="How can I contribute to the mission of ConPEC?"
          >
            <div class="answer">
              You can contribute to this effort in various ways. As an
              instructor, you can contribute by using the platform to connect
              with practitioners and inviting people (instructors and
              practitioners) to also use the platform. As a practitioner, you
              can contribute by using the platform to engage with instructors to
              contribute to student development. You can also tell other people
              about ConPEC and encourage them to do the same. You can also
              contribute by contacting us
              <router-link :to="{ name: 'contact-us' }"> here</router-link> to
              provide feedback, comments, and suggestions to improve the
              platform.
            </div>
          </va-collapse>
          <va-collapse
            v-model="values[8]"
            class="w-96 my-3"
            header="What does course-support mean?"
          >
            <div class="answer">
              Course-support represents different ways by which practitioners
              can support instructors’ pedagogical efforts. These include
              facilitating site visits and laboratory sessions and serving as
              guest speakers for classroom teaching, workshops, and seminars.
              These also include serving as judge, mentor, and sponsor for term
              projects or capstone projects. These represent different avenues
              students can interact with practitioners and learn from them under
              the supervision of an instructor.
            </div>
          </va-collapse>
          <va-collapse
            v-model="values[9]"
            class="w-96 my-3"
            header="How do you describe the different types of course-support?"
          >
            <div class="answer">
              Below is a brief description of different types of course-support.
              <ul>
                <li>
                  <b>Site visit</b> is a means to expose students to the
                  workplace and work practices on construction job sites.
                </li>
                <li>
                  <b>Guest lecture</b> entails bringing practitioners into the
                  classroom to discuss technical topics from their experience.
                </li>
                <li>
                  <b>Seminar</b> is for sharing knowledge and facilitating
                  discussions on a specific topic or subject. It can also be an
                  avenue to present their ideas and experiences to students.
                </li>
                <li>
                  <b>Workshop</b> is an interactive learning and skill-building
                  session to provide students with hands-on experience and
                  practical training in a specific area or to develop certain
                  skills.
                </li>
                <li>
                  <b>Laboratory session</b> is a practical, hands-on learning
                  experience that takes place in a laboratory where students get
                  the opportunity to apply theoretical concepts to real-world
                  construction tasks.
                </li>
                <li>
                  <b>Judge for a project:</b> This involves assessing and
                  appraising students’ projects based on established guidelines
                  and client expectations.
                </li>
                <li>
                  <b>Mentor for project:</b> This entails providing professional
                  guidance to students throughout the delivery of their project.
                </li>
                <li>
                  <b>Sponsor for a project:</b> This entails providing context
                  for students’ projects and acting as a client.
                </li>
              </ul>
            </div>
          </va-collapse>
          <va-collapse
            v-for="(faq, index) in content"
            v-model="values[10 + index]"
            class="w-96 my-3"
            v-bind:key="faq.id"
            :header="faq.userQuestion"
          >
            <div class="answer">
              {{ faq.answer }}
            </div>
          </va-collapse>
        </div>
        Do you have any questions not addressed above? If yes, please submit it
        here.<span class="super font-color-red">*</span>
        <Form @submit="handleSubmission" :validation-schema="schema">
          <Field name="userQuestion" class="form-control" type="text" />
          <ErrorMessage name="userQuestion" class="error-feedback" />
          <div class="form-group text-align-center mt-3">
            <button class="btn btn-primary btn-block" :disabled="loading">
              <span
                v-show="loading"
                class="spinner-border spinner-border-sm"
              ></span>
              Submit
            </button>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Form, Field, ErrorMessage, useForm } from "vee-validate";
import BackButton from "@/components/user/BackButton.vue";
import * as yup from "yup";
import UserService from "@/services/user.service.js";
import { showToast } from "@/utils";
import { FAQ } from "@/types";

const values = ref([
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
  false,
]);
let loading = ref(false);
let successful = ref(false);
let message = ref("");
let content = ref([] as FAQ[]);

const schema = yup.object().shape({
  userQuestion: yup
    .string()
    .trim()
    .required("This is a required field!")
    .min(1, "This is a required field!")
    .max(1000, "Question cannot be more than 1000 characters!"),
});

const getFAQ = () => {
  UserService.getFAQ().then(
    (response) => {
      console.log(response.data);
      content.value = response.data;
      console.log(values.value.length);
      for (let i = 0; i < content.value.length; i++) values.value.push(false);
      console.log(values.value.length);
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

const handleSubmission = (data, { resetForm }) => {
  loading.value = true;
  UserService.saveFAQ(data).then(
    (_) => {
      successful.value = true;
      loading.value = false;
      showToast("Question submitted successfully.");
      resetForm();
    },
    (error) => {
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      successful.value = false;
      loading.value = false;
    }
  );
};

getFAQ();
</script>

<style scoped>
.answer {
  padding-left: 1rem;
  padding-top: 0.5rem;
}

ul {
  list-style-type: disc;
}

.answer > ul {
  padding-left: 1rem;
}

.image-large {
  max-width: 120px; /* Adjust the size of the image */
  max-height: 120px; /* Maintain aspect ratio */
  object-fit: contain; /* Ensure it scales without distortion */
  margin: 0 auto; /* Center the image horizontally */
}
</style>
