<template>
  <div class="grid-container">
    <div v-if="currentUser?.roles === 'INDUSTRYPRACTITIONER'">
      <practitioner-sidebar></practitioner-sidebar>
    </div>
    <div v-else-if="currentUser?.roles === 'INSTRUCTOR'">
      <instructor-sidebar></instructor-sidebar>
    </div>
    <div class="min-height pt-3">
      <div class="back-grid mb-3">
        <back-button></back-button>
        <h2 style="text-align: center; font-weight: bold">
          Industry Practitioner Details
        </h2>
      </div>
      <div
        v-if="successful && industryPractitioner"
        style="
          display: flex;
          justify-content: space-evenly;
          align-items: center;
        "
      >
        <div>
          <font-awesome-icon
            class="prev-icon"
            title="View Previous Practitioner"
            v-if="RecommendationsStore?.count > 1"
            @click="RecommendationsStore.moveToPrev"
            icon="fa-solid fa-chevron-left"
          />
        </div>
        <div>
          <h5 class="text-align-center font-italic font-bold">
            Practitioner’s Basic Information
          </h5>
          <table class="table">
            <tr>
              <td>Name</td>
              <td>
                {{
                  industryPractitioner?.user?.firstName +
                  " " +
                  industryPractitioner?.user?.lastName
                }}
              </td>
            </tr>
            <tr>
              <td>Title</td>
              <td>{{ industryPractitioner?.user?.title || "N/A" }}</td>
            </tr>
            <tr v-if="valid(industryPractitioner?.user?.pronouns)">
              <td>Pronouns</td>
              <td>{{ industryPractitioner?.user?.pronouns }}</td>
            </tr>
            <tr v-if="valid(industryPractitioner?.position)">
              <td>Position</td>
              <td>{{ industryPractitioner?.position || "N/A" }}</td>
            </tr>
            <tr>
              <td>Years of Experience</td>
              <td>{{ industryPractitioner?.yearsOfExp || "N/A" }}</td>
            </tr>
            <tr>
              <td>Level of Education</td>
              <td>{{ industryPractitioner?.levelEducation || "N/A" }}</td>
            </tr>
            <tr>
              <td>Area of Specialization</td>
              <td>
                {{
                  JSON.parse(industryPractitioner?.expertise || '["N/A"]').join(
                    ", "
                  )
                }}
              </td>
            </tr>
            <tr>
              <td>Professional License</td>
              <td>
                {{
                  JSON.parse(
                    industryPractitioner?.profLicense || '["N/A"]'
                  ).join(", ")
                }}
              </td>
            </tr>
            <tr
              v-if="course?.status === '10' || course?.status === '20'"
              style="font-style: italic"
            >
              <td>Email</td>
              <td>
                <a :href="'mailto:' + industryPractitioner?.user?.email">
                  {{ industryPractitioner?.user?.email }}
                </a>
              </td>
            </tr>
            <tr
              v-if="
                (course?.status === '10' || course?.status === '20') &&
                industryPractitioner?.user?.phone === 'True'
              "
              style="font-style: italic"
            >
              <td>Phone Number</td>
              <td>{{ industryPractitioner?.user?.phoneNumber }}</td>
            </tr>
          </table>
          <h5 class="text-align-center font-italic font-bold">
            Practitioner’s Organization Basic Information
          </h5>
          <table class="table">
            <tr>
              <td>Organization</td>
              <td>{{ industryPractitioner?.user?.nameOrg || "N/A" }}</td>
            </tr>
            <tr>
              <td>Location</td>
              <td>
                {{
                  industryPractitioner?.city + " " + industryPractitioner?.state
                }}
              </td>
            </tr>
            <tr>
              <td>Type of Organization</td>
              <td>{{ industryPractitioner?.type || "N/A" }}</td>
            </tr>
            <tr>
              <td>Size of Organization</td>
              <td>{{ industryPractitioner?.size || "N/A" }}</td>
            </tr>
            <tr>
              <td>Gender Diversity of Organization</td>
              <td>{{ industryPractitioner?.genderDiv || "N/A" }}</td>
            </tr>
            <tr>
              <td>Racial Diversity of Organization</td>
              <td>{{ industryPractitioner?.ethnicDiv || "N/A" }}</td>
            </tr>
            <tr>
              <td>Website of Organization</td>
              <td>
                <a
                  v-if="industryPractitioner?.website"
                  :href="
                    industryPractitioner.website.startsWith('http')
                      ? industryPractitioner.website
                      : 'https://' + industryPractitioner.website
                  "
                  target="_blank"
                >
                  {{ industryPractitioner.website }}
                </a>
              </td>
            </tr>
            <tr v-if="valid(industryPractitioner?.typeProject)">
              <td>Types of Projects Executed</td>
              <td>
                {{
                  JSON.parse(
                    industryPractitioner?.typeProject || '["N/A"]'
                  ).join(", ")
                }}
              </td>
            </tr>
            <tr v-if="valid(industryPractitioner?.typeTechnology)">
              <td>
                Types of Technologies Adopted by Practitioner's Organization
              </td>
              <td>
                {{
                  JSON.parse(
                    industryPractitioner?.typeTechnology || '["N/A"]'
                  ).join(", ")
                }}
              </td>
            </tr>
            <tr v-if="valid(industryPractitioner?.organPolicy)">
              <td>Company's Effort to Support Student Development</td>
              <td>{{ industryPractitioner?.organPolicy || "N/A" }}</td>
            </tr>
          </table>
        </div>
        <div>
          <font-awesome-icon
            class="next-icon"
            title="View Next Practitioner"
            v-if="RecommendationsStore?.count > 1"
            @click="RecommendationsStore.moveToNext"
            icon="fa-solid fa-chevron-right"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import InstructorService from "@/services/instructor.service.js";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { useRouter } from "vue-router";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { valid } from "@/utils";

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const RecommendationsStore = useRecommendationsStore();
const router = useRouter();
const currentUser = AuthStore.auth;

let course = CourseStore.course;
let industryPractitioner = ref([]);
let loading = ref(false);
let successful = ref(false);
let message = ref("");

const getIndustryPractitionerProfile = (industryPractitionerUserId) => {
  loading.value = true;
  InstructorService.getIndustryPractitionerProfile(
    industryPractitionerUserId
  ).then(
    (response) => {
      industryPractitioner.value = response.data;
      successful.value = true;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getIndustryPractitionerProfile(CourseStore.practitionerId);

watch(
  () => RecommendationsStore.practitionerId,
  (newPractitionerId) => {
    if (newPractitionerId > 0)
      getIndustryPractitionerProfile(newPractitionerId);
  },
  { immediate: true }
);
</script>

<style scoped>
table {
  min-width: fit-content;
  width: 65vw;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 2em;
  font-size: larger;
}

.next-icon,
.prev-icon {
  font-size: xxx-large;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  td {
    padding: 0.2em 0.25em;
    font-size: small;
    overflow-wrap: anywhere;
  }
}
</style>
