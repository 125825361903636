import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "container min-height" }
const _hoisted_5 = { class: "back-grid" }
const _hoisted_6 = { style: {"text-align":"center","font-weight":"bold"} }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "data-view-container" }
const _hoisted_10 = { class: "user-card border-1 surface-border surface-card border-round cursor-pointer" }
const _hoisted_11 = ["src"]
const _hoisted_12 = {
  key: 1,
  id: "profile-img",
  src: "//ssl.gstatic.com/accounts/ui/avatar_2x.png",
  class: "recommendation-img-card"
}
const _hoisted_13 = { class: "user-details" }
const _hoisted_14 = { class: "user-name" }
const _hoisted_15 = { class: "user-position" }
const _hoisted_16 = { class: "user-location" }

import UserService from "@/services/user.service.js";
import { ref, watch } from "vue";
import { websiteURL } from "@/utils";
import { useRoute } from "vue-router";
import { useAuthStore } from "@/stores/AuthStore";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import DataView from "primevue/dataview";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserListView',
  setup(__props) {

const AuthStore = useAuthStore();

const currentUser = AuthStore.auth;
const route = useRoute();
const userList = ref([]);

const getUserList = (role) => {
  UserService.getUsers(role).then(
    (response) => {
      userList.value = response.data;
    },
    (error) => {
      let res =
        (error.response &&
          error.response.data &&
          error.response.items.message) ||
        error.message ||
        error.toString();
      console.log(res);
    }
  );
};

watch(
  () => route.params.role,
  (role) => {
    getUserList(role as string);
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(currentUser).roles === 'INDUSTRYPRACTITIONER')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(PractitionerSidebar)
        ]))
      : (_unref(currentUser).roles === 'INSTRUCTOR')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(InstructorSidebar)
          ]))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(BackButton),
        _createElementVNode("h2", _hoisted_6, [
          (_unref(route).params.role === 'industrypractitioner')
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, " Industry Practitioners "))
            : (_openBlock(), _createElementBlock("span", _hoisted_8, "Instructors"))
        ])
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
      _createVNode(_unref(DataView), {
        value: userList.value,
        layout: "grid"
      }, {
        grid: _withCtx((slotProps) => [
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(slotProps.items, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item.id || index,
                class: "grid-item"
              }, [
                _createVNode(_component_router_link, {
                  to: {
                  name: 'user-profile',
                  params: { userId: item.id },
                }
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_10, [
                      (item.fileName)
                        ? (_openBlock(), _createElementBlock("img", {
                            key: 0,
                            id: "profile-img",
                            src: _unref(websiteURL) + 'profile_picture/' + item.fileName,
                            class: "recommendation-img-card"
                          }, null, 8, _hoisted_11))
                        : (_openBlock(), _createElementBlock("img", _hoisted_12)),
                      _createElementVNode("div", _hoisted_13, [
                        _createElementVNode("b", _hoisted_14, _toDisplayString(item.firstName) + " " + _toDisplayString(item.lastName), 1),
                        _createElementVNode("p", _hoisted_15, _toDisplayString(item.position) + " at " + _toDisplayString(item.nameOrg), 1),
                        _createElementVNode("p", _hoisted_16, _toDisplayString(item.city) + ", " + _toDisplayString(item.state), 1)
                      ])
                    ])
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["value"])
    ])
  ]))
}
}

})