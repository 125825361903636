<template>
  <div class="grid-container">
    <app-sidebar />
    <div class="container min-height">
      <div class="back-grid">
        <back-button></back-button>
        <div></div>
      </div>
      <div v-if="successful">
        <!-- Industry Practitioner Section -->
        <div v-if="userProfile && userProfile.industry_practitioner">
          <div class="m-2 lg:m-3 lg:mt-5 heading-container">
            <img
              v-if="userProfile.fileName"
              id="profile-img"
              :src="websiteURL + 'profile_picture/' + userProfile.fileName"
              class="profile-img-card"
            />
            <img
              v-else
              id="profile-img"
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              class="profile-img-card"
            />
            <div class="details">
              <span class="name">
                {{ userProfile.firstName || "N/A" }}
                {{ userProfile.lastName || "N/A" }}
              </span>
              <br />
              <span class="position">
                {{ userProfile.industry_practitioner.position || "N/A" }} at
                {{ userProfile.nameOrg || "N/A" }}
              </span>
              <br />
              <user-badge />
            </div>
            <div
              class="button-container flex flex-direction-row xl:flex-column"
              v-if="userProfile.id === currentUser.id"
            >
              <va-switch
                v-model="privateProfile"
                color="#5123a1"
                off-color="#ffd300"
                size="small"
                class="block lg:hidden"
                style="--va-switch-checker-background-color: #252723"
              >
                <template #innerLabel>
                  <div class="va-text-center">
                    <div v-if="privateProfile">
                      <font-awesome-icon icon="fa-solid fa-lock" />
                      <span> Private </span>
                    </div>
                    <div v-else>
                      <font-awesome-icon icon="fa-solid fa-lock-open" />
                      <span> Public </span>
                    </div>
                  </div>
                </template>
              </va-switch>
              <va-switch
                class="d-none lg:block"
                v-model="privateProfile"
                color="#5123a1"
                off-color="#ffd300"
                style="--va-switch-checker-background-color: #252723"
              >
                <template #innerLabel>
                  <div class="va-text-center">
                    <div v-if="privateProfile">
                      <font-awesome-icon icon="fa-solid fa-lock" />
                      <span> Private </span>
                    </div>
                    <div v-else>
                      <font-awesome-icon icon="fa-solid fa-lock-open" />
                      <span> Public </span>
                    </div>
                  </div>
                </template>
              </va-switch>

              <router-link
                :to="{
                  name: 'industry-practitioner-profile',
                }"
                class="mt-1 lg:mt-3"
              >
                <button class="btn btn-grey sm:ml-1">
                  <font-awesome-icon
                    icon="fa-solid fa-pencil"
                    style="display: inline-block; align-self: center"
                  />
                  <span style="line-height: 2.5rem">&nbsp;Edit Profile</span>
                </button>
              </router-link>
            </div>
          </div>
          <div class="divider" />
          <div class="mt-3" v-if="userProfile.industry_practitioner">
            <h5 class="text-align-center font-italic font-bold">
              Personal Information
            </h5>
            <table class="table">
              <tr>
                <td>Title</td>
                <td>{{ userProfile.title || "N/A" }}</td>
              </tr>
              <tr v-if="privateProfile">
                <td>Gender Identity</td>
                <td>{{ userProfile.gender || "N/A" }}</td>
              </tr>
              <tr v-if="privateProfile">
                <td>Racial Identity</td>
                <td>{{ userProfile.ethnicity || "N/A" }}</td>
              </tr>
              <tr v-if="valid(userProfile.industry_practitioner?.pronouns)">
                <td>Pronouns</td>
                <td>
                  {{ userProfile.industry_practitioner.pronouns || "N/A" }}
                </td>
              </tr>

              <tr v-if="privateProfile">
                <td>Age Range</td>
                <td>
                  {{ userProfile.industry_practitioner?.ageRange || "N/A" }}
                </td>
              </tr>

              <tr>
                <td>Years of Experience</td>
                <td>
                  {{ userProfile.industry_practitioner?.yearsOfExp || "N/A" }}
                </td>
              </tr>

              <tr>
                <td>Highest Level of Education</td>
                <td>
                  {{
                    userProfile.industry_practitioner?.levelEducation || "N/A"
                  }}
                </td>
              </tr>

              <tr v-if="privateProfile">
                <td>Physical Disabilities</td>
                <td>
                  {{
                    userProfile.industry_practitioner?.physicalDis
                      ? JSON.parse(
                          userProfile.industry_practitioner.physicalDis
                        ).join(", ")
                      : "N/A"
                  }}
                </td>
              </tr>

              <tr>
                <td>Area of Specialization</td>
                <td>
                  {{
                    userProfile.industry_practitioner?.expertise
                      ? JSON.parse(
                          userProfile.industry_practitioner.expertise
                        ).join(", ")
                      : "N/A"
                  }}
                </td>
              </tr>

              <tr>
                <td>Professional License</td>
                <td>
                  {{
                    userProfile.industry_practitioner?.profLicense
                      ? JSON.parse(
                          userProfile.industry_practitioner.profLicense
                        ).join(", ")
                      : "N/A"
                  }}
                </td>
              </tr>
              <tr>
                <td>Subject willing to speak on</td>
                <td>
                  {{
                    formatSubjectSpeak(
                      userProfile.industry_practitioner_availability
                        ?.subjectSpeak,
                      userProfile.industry_practitioner_availability
                        ?.subjectSpeakOthers
                    ) || "N/A"
                  }}
                </td>
              </tr>
            </table>

            <h5 class="text-align-center font-italic font-bold">
              Company Information
            </h5>
            <table class="table">
              <tr>
                <td>Location</td>
                <td>
                  {{
                    (userProfile.industry_practitioner?.city || "N/A") +
                    " " +
                    (userProfile.industry_practitioner?.state || "N/A")
                  }}
                </td>
              </tr>

              <tr>
                <td>Type of Organization</td>
                <td>{{ userProfile.industry_practitioner?.type || "N/A" }}</td>
              </tr>

              <tr>
                <td>Size of Organization</td>
                <td>{{ userProfile.industry_practitioner?.size || "N/A" }}</td>
              </tr>

              <tr>
                <td>Gender Diversity of Organization</td>
                <td>
                  {{ userProfile.industry_practitioner?.genderDiv || "N/A" }}
                </td>
              </tr>

              <tr>
                <td>Racial Diversity of Organization</td>
                <td>
                  {{ userProfile.industry_practitioner?.ethnicDiv || "N/A" }}
                </td>
              </tr>
              <tr>
                <td>Website of Organization</td>
                <td>
                  <template v-if="userProfile.industry_practitioner?.website">
                    <a
                      v-if="
                        !userProfile.industry_practitioner.website.startsWith(
                          'http'
                        )
                      "
                      :href="
                        'https://' + userProfile.industry_practitioner.website
                      "
                      target="_blank"
                    >
                      {{ userProfile.industry_practitioner.website }}
                    </a>
                    <a
                      v-else
                      :href="userProfile.industry_practitioner.website"
                      target="_blank"
                    >
                      {{ userProfile.industry_practitioner.website }}
                    </a>
                  </template>
                  <span v-else>N/A</span>
                </td>
              </tr>

              <tr v-if="valid(userProfile.industry_practitioner?.typeProject)">
                <td>Types of Projects Executed</td>
                <td>
                  {{
                    userProfile.industry_practitioner?.typeProject
                      ? JSON.parse(
                          userProfile.industry_practitioner.typeProject
                        ).join(", ")
                      : "N/A"
                  }}
                </td>
              </tr>

              <tr
                v-if="valid(userProfile.industry_practitioner?.typeTechnology)"
              >
                <td>
                  Types of Technologies Adopted by Practitioner's Organization
                </td>
                <td>
                  {{
                    userProfile.industry_practitioner?.typeTechnology
                      ? JSON.parse(
                          userProfile.industry_practitioner.typeTechnology
                        ).join(", ")
                      : "N/A"
                  }}
                </td>
              </tr>

              <tr v-if="valid(userProfile.industry_practitioner?.organPolicy)">
                <td>Company's Effort to Support Student Development</td>
                <td>
                  {{ userProfile.industry_practitioner?.organPolicy || "N/A" }}
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- Instructor Section -->
        <div v-else-if="userProfile && userProfile.department">
          <div class="mt-5 heading-container">
            <img
              v-if="userProfile.user?.fileName"
              id="profile-img"
              :src="websiteURL + 'profile_picture/' + userProfile.user.fileName"
              class="profile-img-card"
            />
            <img
              v-else
              id="profile-img"
              src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
              class="profile-img-card"
            />
            <div class="details">
              <span class="name">
                {{ userProfile?.user.firstName || "N/A" }}
                {{ userProfile?.user.lastName || "N/A" }}
              </span>
              <br />
              <span class="position">
                {{ userProfile?.user.title || "N/A" }} at
                {{ userProfile?.user.nameOrg || "N/A" }}
              </span>
            </div>
            <div
              class="button-container flex flex-direction-row xl:flex-column"
              v-if="userProfile?.user.id === currentUser.id"
            >
              <!-- Switches and Edit Profile button remain the same -->
              <va-switch
                v-model="privateProfile"
                color="#5123a1"
                off-color="#ffd300"
                style="--va-switch-checker-background-color: #252723"
                class="block lg:hidden"
                size="small"
              >
                <template #innerLabel>
                  <div class="va-text-center">
                    <div v-if="privateProfile">
                      <font-awesome-icon icon="fa-solid fa-lock" />
                      <span> Private </span>
                    </div>
                    <div v-else>
                      <font-awesome-icon icon="fa-solid fa-lock-open" />
                      <span> Public </span>
                    </div>
                  </div>
                </template>
              </va-switch>

              <va-switch
                v-model="privateProfile"
                color="#5123a1"
                off-color="#ffd300"
                class="hidden lg:block"
                style="--va-switch-checker-background-color: #252723"
              >
                <template #innerLabel>
                  <div class="va-text-center">
                    <div v-if="privateProfile">
                      <font-awesome-icon icon="fa-solid fa-lock" />
                      <span> Private </span>
                    </div>
                    <div v-else>
                      <font-awesome-icon icon="fa-solid fa-lock-open" />
                      <span> Public </span>
                    </div>
                  </div>
                </template>
              </va-switch>

              <router-link :to="{ name: 'instructor-profile' }" class="mt-3">
                <button class="btn btn-grey sm:ml-1">
                  <font-awesome-icon
                    icon="fa-solid fa-pencil"
                    style="display: inline-block; align-self: center"
                  />
                  <span style="line-height: 2.5rem">&nbsp;Edit Profile</span>
                </button>
              </router-link>
            </div>
          </div>
          <div class="divider" />
          <h5 class="text-align-center font-italic font-bold">
            Personal Information
          </h5>
          <table class="table">
            <tr v-if="privateProfile">
              <td>Gender Identity</td>
              <td>{{ userProfile?.user.gender || "N/A" }}</td>
            </tr>
            <tr v-if="privateProfile">
              <td>Racial Identity</td>
              <td>{{ userProfile?.user.ethnicity || "N/A" }}</td>
            </tr>
            <tr v-if="privateProfile">
              <td>Age Range</td>
              <td>{{ userProfile.ageRange || "N/A" }}</td>
            </tr>
            <tr v-if="privateProfile">
              <td>Years of Faculty Experience</td>
              <td>{{ userProfile.yearsOfExp || "N/A" }}</td>
            </tr>
            <tr v-if="valid(userProfile.pronouns)">
              <td>Pronouns</td>
              <td>{{ userProfile.pronouns || "N/A" }}</td>
            </tr>

            <tr>
              <td>Office Address</td>
              <td>{{ userProfile.officeAddress || "N/A" }}</td>
            </tr>
            <tr>
              <td>Department/Program</td>
              <td>{{ userProfile.department || "N/A" }}</td>
            </tr>
            <tr v-if="privateProfile">
              <td>Type of Institution</td>
              <td>{{ userProfile.insType || "N/A" }}</td>
            </tr>
            <tr>
              <td>Location of Institution</td>
              <td>
                {{ userProfile.parkingAddress || "N/A" }} <br />
                {{
                  (userProfile.city || "N/A") +
                  " " +
                  (userProfile.state || "N/A")
                }}
              </td>
            </tr>
            <tr>
              <td>Parking Arrangement for Practitioners</td>
              <td>{{ userProfile.parkingArrangement || "N/A" }}</td>
            </tr>
          </table>
        </div>
        <div v-else class="text-align: center;">No user data available</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { websiteURL, valid, formatSubjectSpeak } from "@/utils";
import AppSidebar from "@/components/user/AppSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import UserBadge from "@/components/user/UserBadge.vue";
import UserService from "@/services/user.service.js";
import { useAuthStore } from "@/stores/AuthStore";
import { useRoute } from "vue-router";

const AuthStore = useAuthStore();
const route = useRoute();
const currentUser = AuthStore.auth;
let userProfile = ref({});
let privateProfile = ref(false);
let loading = ref(false);
let successful = ref(false);
let message = ref("");

const getUserProfile = (data) => {
  loading.value = true;
  UserService.getUserProfile(data).then(
    (response) => {
      console.log(response.data);
      userProfile.value = response.data;
      loading.value = false;
      successful.value = true;
    },
    (error) => {
      loading.value = false;
      successful.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

watch(
  () => route.params.userId,
  (userId) => {
    getUserProfile(userId as string);
  },
  { immediate: true }
);
</script>

<style scoped>
table {
  width: fit-content;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
  padding: 1rem;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 2em;
  font-size: larger;
  overflow-wrap: break-word;
}

.details {
  flex-grow: 1;
  align-self: center;
  margin-left: 2rem;
}

.button-container {
  flex-grow: 0;
  margin: 2rem 2rem 1rem;
  align-self: center;
}

.name {
  flex-grow: 1;
  align-self: center;
  line-height: 1.1875;
  font-weight: 700;
  font-size: 2rem;
}

.position {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.0325rem;
}

.profile-img-card {
  width: 168px;
  height: 168px;
  margin: 10px auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.divider {
  border-top: 2px solid #ced0d4;
  margin-right: 2rem;
}

.btn-grey {
  color: black;
  background: #d9d9d9;
}

.btn-grey:hover {
  background: #cccccc;
}

.heading-container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
}

@media only screen and (max-width: 768px) {
  td {
    padding: 0.2em 0.25em;
    font-size: small;
    overflow-wrap: anywhere;
  }

  tr td:nth-child(1) {
    margin-left: 0.25em;
  }

  .heading-container {
    flex-direction: column;
  }

  .name {
    text-align: center;
    font-size: 1.25rem;
  }

  .button-container {
    justify-content: center;
    align-items: baseline;
    margin: 0;
  }

  .details {
    margin-left: 0.25rem;
    text-align: center;
  }

  .profile-img-card {
    width: 84px;
    height: 84px;
    margin: 5px auto 5px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
}
</style>
