<template>
  <div class="grid-container">
    <instructor-sidebar></instructor-sidebar>
    <div class="content min-height m-3">
      <div class="back-grid">
        <button
          @click="handleClick"
          style="
            background: none;
            border: none;
            outline: none;
            padding: 0;
            cursor: pointer;
            font-weight: bold;
          "
          title="Go back"
        >
          <font-awesome-icon icon="chevron-left" style="font-weight: bold" />
          Back
        </button>

        <div class="flex flex-column lg:flex-row xl:align-items-center">
          <div>
            <h2
              style="text-align: center; font-weight: bold"
              class="flex-grow-1"
            >
              View Recommendations
            </h2>
            <h5 style="text-align: center">
              You will be provided with the contact information of the
              practitioner(s) you selected.
            </h5>
          </div>
          <div class="flex-shrink-1 flex-grow-0 mr-3 layout-button">
            <DataViewLayoutOptions v-model="FeedStore.recommendationLayout" />
          </div>
        </div>
      </div>
      <div class="mt-5">
        <span class="bold-text">
          A maximum of 3 selections is allowed. If the first practitioner
          declines, your request goes to next practitioner.
        </span>
        <div
          class="flex flex-direction-column lg:flex-row mr-auto ml-auto text-align-center align-center"
        >
          <div class="gallery col-12 sm:col-6 lg:col-12 xl:col-3 p-0">
            <div
              class="w-100"
              v-if="RecommendationsStore.selectedPractitioner.length > 0"
            >
              <recommendation-gallery-item
                :selected-practitioner="
                  RecommendationsStore.selectedPractitioner[0]
                "
                title="1st Choice Practitioner"
              />
            </div>
            <div class="w-100" v-else>1st Practitioner</div>
          </div>
          <div class="gallery col-12 sm:col-6 lg:col-12 xl:col-3 p-0 m-1">
            <div
              class="w-100"
              v-if="RecommendationsStore.selectedPractitioner.length > 1"
            >
              <recommendation-gallery-item
                :selected-practitioner="
                  RecommendationsStore.selectedPractitioner[1]
                "
                title="2nd Choice Practitioner"
              />
            </div>
            <div class="w-100" v-else>2nd Practitioner</div>
          </div>
          <div class="gallery col-12 sm:col-6 lg:col-12 xl:col-3 p-0">
            <div
              class="w-100"
              v-if="RecommendationsStore.selectedPractitioner.length > 2"
            >
              <recommendation-gallery-item
                :selected-practitioner="
                  RecommendationsStore.selectedPractitioner[2]
                "
                title="3rd Choice Practitioner"
              />
            </div>
            <div class="w-100" v-else>3rd Practitioner</div>
          </div>
          <div class="mt-1">
            <button
              class="btn btn-primary"
              :disabled="RecommendationsStore.selectedPractitioner.length === 0"
              @click="showPopup"
            >
              Save Choices
            </button>
          </div>
        </div>
      </div>
      <div class="padding-10 mt-4">
        <div v-if="RecommendationsStore.recommendations.length > 0">
          <DataView
            :value="RecommendationsStore.recommendations || []"
            :layout="FeedStore.recommendationLayout"
          >
            <template #list="slotProps">
              <!-- limiting to ten (10) recommendations max -->
              <div
                v-for="(item, index) in slotProps.items.slice(0, 10)"
                :key="index"
                class="w-100"
                :class="{ disabled: item.disabled }"
              >
                <div
                  class="flex flex-column xl:flex-row xl:align-items-center p-4 gap-4 flex-grow-1"
                  :class="{ 'pointer-events-none': item.disabled }"
                >
                  <!-- Header Details -->
                  <div
                    class="flex flex-column xl:flex-row xl:align-items-center cursor-pointer"
                    @click="item.opened = !item.opened"
                  >
                    <div class="number">{{ index + 1 }}</div>
                    <div class="image-container">
                      <img
                        v-if="item.fileName"
                        id="profile-img"
                        :src="websiteURL + 'profile_picture/' + item.fileName"
                        class="recommendation-img-card"
                      />
                      <img
                        v-else
                        id="profile-img"
                        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                        class="recommendation-img-card"
                      />
                    </div>
                    <div class="flex-grow-1">
                      <b>
                        {{ item.title }} {{ item.firstName }}
                        {{ item.lastName }}, {{ item.city }} {{ item.state }}
                      </b>
                      <br />
                      <b>Position:</b> {{ item.position }} at {{ item.nameOrg }}
                      <br />
                      <b>Area of Specialization:</b>
                      {{ JSON.parse(item.expertise || "[]").join(", ") }}
                      <br />
                      <b>Years of Experience:</b> {{ item.yearsOfExp }}
                      <br />
                      <div
                        v-if="item.pastExperience > 0"
                        @click="getPractitionerExperience($event, item)"
                        class="past-exp-button"
                      >
                        <b>Previous course-support provided:</b>
                        {{ item.pastExperience }}
                      </div>
                      <div v-else>
                        <b>Previous course-support provided:</b>
                        {{ item.pastExperience }}
                      </div>
                    </div>

                    <div class="flex-grow-0 flex-shrink-0 mr-3 more-details">
                      <span v-if="item.opened">
                        <font-awesome-icon icon="fa-solid fa-chevron-up" />
                      </span>
                      <span v-else>
                        <span class="text-sm">View Details</span>
                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                      </span>
                    </div>
                  </div>

                  <!-- Select Button -->
                  <div class="flex-grow-0 flex-shrink-0 mr-5">
                    <button
                      v-if="
                        course.status !== '20' &&
                        course.status !== '10' &&
                        course.status !== '60'
                      "
                      class="btn btn-primary"
                      @click="selectIndustryPractitioner(item)"
                    >
                      Select Practitioner
                    </button>
                  </div>
                </div>

                <!-- Transition for expanded details -->
                <Transition>
                  <div v-if="item.opened">
                    <h5 class="text-align-center font-italic font-bold">
                      Practitioner’s Basic Information
                    </h5>
                    <table class="table">
                      <tr>
                        <td>Name</td>
                        <td>{{ item.firstName }} {{ item.lastName }}</td>
                      </tr>
                      <tr>
                        <td>Title</td>
                        <td>{{ item.title }}</td>
                      </tr>
                      <tr v-if="valid(item.pronouns)">
                        <td>Pronouns</td>
                        <td>{{ item.pronouns }}</td>
                      </tr>
                      <tr v-if="valid(item.position)">
                        <td>Position</td>
                        <td>{{ item.position }}</td>
                      </tr>
                      <tr>
                        <td>Years of Experience</td>
                        <td>{{ item.yearsOfExp }}</td>
                      </tr>
                      <tr>
                        <td>Level of Education</td>
                        <td>{{ item.levelEducation }}</td>
                      </tr>
                      <tr>
                        <td>Area of Specialization</td>
                        <td>
                          {{ JSON.parse(item.expertise || "[]").join(", ") }}
                        </td>
                      </tr>
                      <tr>
                        <td>Professional License</td>
                        <td>
                          {{ JSON.parse(item.profLicense || "[]").join(", ") }}
                        </td>
                      </tr>
                    </table>
                    <h5 class="text-align-center font-italic font-bold">
                      Practitioner’s Organization Basic Information
                    </h5>
                    <table class="table">
                      <tr>
                        <td>Organization</td>
                        <td>{{ item.nameOrg }}</td>
                      </tr>
                      <tr>
                        <td>Location</td>
                        <td>{{ item.city }} {{ item.state }}</td>
                      </tr>
                      <tr>
                        <td>Type of Organization</td>
                        <td>{{ item.type }}</td>
                      </tr>
                      <tr>
                        <td>Size of Organization</td>
                        <td>{{ item.size }}</td>
                      </tr>
                      <tr>
                        <td>Gender Diversity</td>
                        <td>{{ item.genderDiv }}</td>
                      </tr>
                      <tr>
                        <td>Racial Diversity</td>
                        <td>{{ item.ethnicDiv }}</td>
                      </tr>
                      <tr>
                        <td>Website</td>
                        <td>
                          <a
                            v-if="!item.website.startsWith('http')"
                            :href="'https://' + item.website"
                            target="_blank"
                          >
                            {{ item.website }}
                          </a>
                          <a v-else :href="item.website" target="_blank">
                            {{ item.website }}
                          </a>
                        </td>
                      </tr>
                      <tr v-if="valid(item.typeProject)">
                        <td>Types of Projects</td>
                        <td>
                          {{ JSON.parse(item.typeProject || "[]").join(", ") }}
                        </td>
                      </tr>
                      <tr v-if="valid(item.typeTechnology)">
                        <td>Technologies Used</td>
                        <td>
                          {{
                            JSON.parse(item.typeTechnology || "[]").join(", ")
                          }}
                        </td>
                      </tr>
                      <tr v-if="valid(item.organPolicy)">
                        <td>Support Policy</td>
                        <td>{{ item.organPolicy }}</td>
                      </tr>
                    </table>
                  </div>
                </Transition>
              </div>
            </template>

            <template #grid="slotProps">
              <!-- limiting to ten (10) recommendations max -->

              <div class="data-view-container">
                <div
                  v-for="(item, index) in slotProps.items.slice(0, 10)"
                  :key="index"
                  class="border-1 surface-border surface-card border-round p-2"
                  :class="{
                    disabled: item.disabled,
                    'pointer-events-none': item.disabled,
                  }"
                >
                  <div class="cursor-pointer">
                    <!-- Image Section -->
                    <div>
                      <img
                        v-if="item.fileName"
                        id="profile-img"
                        :src="websiteURL + 'profile_picture/' + item.fileName"
                        class="recommendation-img-card"
                      />
                      <img
                        v-else
                        id="profile-img"
                        src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
                        class="recommendation-img-card"
                      />
                    </div>

                    <!-- Header Details -->
                    <div class="text-align-center text-wrap">
                      <div class="bold-text">
                        {{ item.title }} {{ item.firstName }}
                        {{ item.lastName }}
                      </div>
                      <div class="bold-text">
                        {{ item.position }} at {{ item.nameOrg }},
                        {{ item.city }} {{ item.state }}
                      </div>
                      <div>
                        <b>Area of specialization:</b>
                        {{
                          item.expertise
                            ? JSON.parse(item.expertise || "[]").join(", ")
                            : "N/A"
                        }}
                        <br />
                        <b>Years of experience:</b>
                        {{ item.yearsOfExp || "N/A" }}
                        <div
                          v-if="item.pastExperience > 0"
                          @click="getPractitionerExperience($event, item)"
                          class="past-exp-button"
                        >
                          <b>Previous course-support provided:</b>
                          {{ item.pastExperience }}
                        </div>
                        <div v-else>
                          <b>Previous course-support provided:</b>
                          {{ item.pastExperience }}
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Select Button -->
                  <div
                    class="flex flex-direction-column justify-content-between text-align-center mt-2"
                  >
                    <!-- View Details -->
                    <div
                      class="more-details"
                      @click="item.opened = !item.opened"
                    >
                      <span v-if="item.opened">
                        <font-awesome-icon icon="fa-solid fa-chevron-up" />
                      </span>
                      <span v-else>
                        <span class="text-sm">View Details</span>
                        <font-awesome-icon icon="fa-solid fa-chevron-down" />
                      </span>
                    </div>

                    <!-- Select Practitioner -->
                  </div>

                  <!-- Expanded Details Section -->
                  <Transition>
                    <div v-if="item.opened">
                      <h5 class="text-align-center font-italic font-bold">
                        Practitioner’s Basic Information
                      </h5>
                      <table class="table">
                        <tr>
                          <td>Name</td>
                          <td>{{ item.firstName }} {{ item.lastName }}</td>
                        </tr>
                        <tr>
                          <td>Title</td>
                          <td>{{ item.title }}</td>
                        </tr>
                        <tr v-if="valid(item.pronouns)">
                          <td>Pronouns</td>
                          <td>{{ item.pronouns }}</td>
                        </tr>
                        <tr v-if="valid(item.position)">
                          <td>Position</td>
                          <td>{{ item.position }}</td>
                        </tr>
                        <tr>
                          <td>Years of Experience</td>
                          <td>{{ item.yearsOfExp }}</td>
                        </tr>
                        <tr>
                          <td>Level of Education</td>
                          <td>{{ item.levelEducation }}</td>
                        </tr>
                        <tr>
                          <td>Area of Specialization</td>
                          <td>
                            {{ JSON.parse(item.expertise || "[]").join(", ") }}
                          </td>
                        </tr>
                        <tr>
                          <td>Professional License</td>
                          <td>
                            {{
                              JSON.parse(item.profLicense || "[]").join(", ")
                            }}
                          </td>
                        </tr>
                      </table>
                      <h5 class="text-align-center font-italic font-bold">
                        Practitioner’s Organization Basic Information
                      </h5>
                      <table class="table">
                        <tr>
                          <td>Organization</td>
                          <td>{{ item.nameOrg }}</td>
                        </tr>
                        <tr>
                          <td>Location</td>
                          <td>{{ item.city }} {{ item.state }}</td>
                        </tr>
                        <tr>
                          <td>Type of Organization</td>
                          <td>{{ item.type }}</td>
                        </tr>
                        <tr>
                          <td>Size of Organization</td>
                          <td>{{ item.size }}</td>
                        </tr>
                        <tr>
                          <td>Gender Diversity</td>
                          <td>{{ item.genderDiv }}</td>
                        </tr>
                        <tr>
                          <td>Racial Diversity</td>
                          <td>{{ item.ethnicDiv }}</td>
                        </tr>
                        <tr>
                          <td>Website</td>
                          <td>
                            <a
                              v-if="!item.website.startsWith('http')"
                              :href="'https://' + item.website"
                              target="_blank"
                            >
                              {{ item.website }}
                            </a>
                            <a v-else :href="item.website" target="_blank">
                              {{ item.website }}
                            </a>
                          </td>
                        </tr>
                        <tr v-if="valid(item.typeProject)">
                          <td>Types of Projects</td>
                          <td>
                            {{
                              JSON.parse(item.typeProject || "[]").join(", ")
                            }}
                          </td>
                        </tr>
                        <tr v-if="valid(item.typeTechnology)">
                          <td>Technologies Used</td>
                          <td>
                            {{
                              JSON.parse(item.typeTechnology || "[]").join(", ")
                            }}
                          </td>
                        </tr>
                        <tr v-if="valid(item.organPolicy)">
                          <td>Support Policy</td>
                          <td>{{ item.organPolicy }}</td>
                        </tr>
                      </table>
                    </div>
                  </Transition>
                  <button
                    v-if="
                      course.status !== '20' &&
                      course.status !== '10' &&
                      course.status !== '60'
                    "
                    class="btn btn-primary mt-2 text-align-center"
                    @click="selectIndustryPractitioner(item)"
                  >
                    Select Practitioner
                  </button>
                </div>
              </div>
            </template>
          </DataView>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-if="isVisible"
    v-model:visible="isVisible"
    modal
    header="Provide Course Support"
    :style="{ width: '40vw' }"
    :breakpoints="{ '768px': '75vw' }"
  >
    <Form
      @submit="saveSelectedPractitioners"
      :validation-schema="schema"
      v-slot="{ meta }"
    >
      <div class="form-group">
        <div
          style="display: flex; justify-content: space-between; height: 100%"
        >
          <label for="msg"
            >Please provide any message you would like to send to
            practitioner</label
          >
        </div>
        <Field
          name="msg"
          type="text"
          class="form-control"
          style="height: 200px"
          as="textarea"
          :value="getCustomMsg()"
        />
        <ErrorMessage name="msg" class="error-feedback" />
      </div>

      <br />
      <div class="form-group text-align-center">
        <button
          class="btn btn-primary btn-block"
          :disabled="loading || !meta.valid"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Send to Industry Practitioners</span>
        </button>
      </div>
    </Form>
  </Dialog>
  <Dialog
    v-if="isVisible"
    v-model:visible="isPractitionerDetailsVisible"
    modal
    :style="{ width: '60vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <template #header>
      <div style="text-align: left">
        <span class="bold-text" style="font-size: x-large">{{
          currIndustryPractitioner?.firstName +
          " " +
          currIndustryPractitioner?.lastName
        }}</span
        >,&nbsp;<i>{{
          currIndustryPractitioner?.position +
          " at " +
          currIndustryPractitioner.nameOrg
        }}</i>
      </div>
    </template>
    <div>
      <h5 class="text-align-center font-italic font-bold">
        Practitioner’s Basic Information
      </h5>
      <table class="table">
        <tr>
          <td>Name</td>
          <td>
            {{
              currIndustryPractitioner.firstName +
              " " +
              currIndustryPractitioner.lastName
            }}
          </td>
        </tr>
        <tr>
          <td>Title</td>
          <td>{{ currIndustryPractitioner?.title }}</td>
        </tr>
        <tr v-if="valid(currIndustryPractitioner.pronouns)">
          <td>Pronouns</td>
          <td>{{ currIndustryPractitioner.pronouns }}</td>
        </tr>
        <tr v-if="valid(currIndustryPractitioner.position)">
          <td>Position</td>
          <td>{{ currIndustryPractitioner.position }}</td>
        </tr>
        <tr>
          <td>Years of Experience</td>
          <td>{{ currIndustryPractitioner.yearsOfExp }}</td>
        </tr>
        <tr>
          <td>Level of Education</td>
          <td>{{ currIndustryPractitioner.levelEducation }}</td>
        </tr>

        <tr>
          <td>Area of Specialization</td>
          <td>
            {{
              JSON.parse(currIndustryPractitioner?.expertise || '[""]').join(
                ", "
              )
            }}
          </td>
        </tr>

        <tr>
          <td>Professional License</td>
          <td>
            {{
              JSON.parse(currIndustryPractitioner?.profLicense || '[""]').join(
                ", "
              )
            }}
          </td>
        </tr>
      </table>
      <h5 class="text-align-center font-italic font-bold">
        Practitioner’s Organization Basic Information
      </h5>
      <table class="table">
        <tr>
          <td>Organization</td>
          <td>{{ currIndustryPractitioner?.nameOrg }}</td>
        </tr>
        <tr>
          <td>Location</td>
          <td>
            {{
              currIndustryPractitioner.city +
              " " +
              currIndustryPractitioner.state
            }}
          </td>
        </tr>

        <tr>
          <td>Type of Organization</td>
          <td>{{ currIndustryPractitioner.type }}</td>
        </tr>

        <tr>
          <td>Size of Organization</td>
          <td>{{ currIndustryPractitioner.size }}</td>
        </tr>

        <tr>
          <td>Gender Diversity of Organization</td>
          <td>{{ currIndustryPractitioner.genderDiv }}</td>
        </tr>

        <tr>
          <td>Racial Diversity of Organization</td>
          <td>{{ currIndustryPractitioner.ethnicDiv }}</td>
        </tr>

        <tr>
          <td>Website of Organization</td>
          <td>
            <a
              v-if="!currIndustryPractitioner.website.startsWith('http')"
              :href="'https://' + currIndustryPractitioner.website"
              target="_blank"
              >{{ currIndustryPractitioner.website }}
            </a>
            <a
              v-else
              :href="currIndustryPractitioner.website"
              target="_blank"
              >{{ currIndustryPractitioner.website }}</a
            >
          </td>
        </tr>

        <tr v-if="valid(currIndustryPractitioner.typeProject)">
          <td>Types of Projects Executed</td>
          <td>
            {{
              JSON.parse(currIndustryPractitioner?.typeProject || '[""]').join(
                ", "
              )
            }}
          </td>
        </tr>

        <tr v-if="valid(currIndustryPractitioner.typeTechnology)">
          <td>Types of Technologies Adopted by Practitioner's Organization</td>
          <td>
            {{
              JSON.parse(
                currIndustryPractitioner?.typeTechnology || '[""]'
              ).join(", ")
            }}
          </td>
        </tr>

        <tr v-if="valid(currIndustryPractitioner.organPolicy)">
          <td>Company's Effort to Support Student Development</td>
          <td>{{ currIndustryPractitioner.organPolicy }}</td>
        </tr>
      </table>
    </div>
  </Dialog>
  <Dialog
    v-if="isVisible"
    v-model:visible="isPractitionerExperienceVisible"
    modal
    :style="{ width: '60vw' }"
    :breakpoints="{ '768px': '90vw' }"
  >
    <template #header>
      <div style="text-align: left">
        <span class="bold-text" style="font-size: x-large">{{
          currIndustryPractitioner?.firstName +
          " " +
          currIndustryPractitioner?.lastName
        }}</span
        >,&nbsp;<i>{{
          currIndustryPractitioner?.position +
          " at " +
          currIndustryPractitioner.nameOrg
        }}</i>
      </div>
    </template>
    <div>
      <table class="table">
        <tr
          v-for="currRow in pastExperienceData"
          v-bind:key="currRow.courseRequestType"
        >
          <td>{{ currRow.courseRequestType }}</td>
          <td>{{ currRow.count_exp }}</td>
        </tr>
      </table>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { showToast, websiteURL, valid } from "@/utils";
import * as yup from "yup";
import { Form, Field, ErrorMessage } from "vee-validate";
import Dialog from "primevue/dialog";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import RecommendationGalleryItem from "@/components/instructor/RecommendationGalleryItem.vue";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import { useCourseStore } from "@/stores/CourseStore";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { useAuthStore } from "@/stores/AuthStore";
import { useFeedStore } from "@/stores/FeedStore";
import { useRouter } from "vue-router";
import InstructorService from "@/services/instructor.service.js";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const CourseStore = useCourseStore();
const RecommendationsStore = useRecommendationsStore();
const AuthStore = useAuthStore();
const FeedStore = useFeedStore();
const router = useRouter();

let loading = ref(false);
let message = ref("");
let successful = ref(false);
let isVisible = ref(false);
let isPractitionerDetailsVisible = ref(false);
let isPractitionerExperienceVisible = ref(false);
let currIndustryPractitioner = ref({});
let pastExperienceData = ref([]);
const currentUser = AuthStore.auth;
const recommendations = RecommendationsStore.recommendations || [];

const showPopup = () => {
  isVisible.value = true;
};

const schema = yup.object().shape({
  msg: yup.string().required("Message to practitioner  is required!"),
});

const getCustomMsg = () => {
  if (currentUser.phone === "True") {
    return `I, ${currentUser.title} ${currentUser.firstName} ${currentUser.lastName} wants you to provide the course-support for (${course.courseRequestType}).\nYou can reach me at ${currentUser.email} or ${currentUser.phoneNumber}.`;
  } else {
    return `I, ${currentUser.title} ${currentUser.firstName} ${currentUser.lastName} wants you to provide the course-support for (${course.courseRequestType}).\nYou can reach me at ${currentUser.email}.`;
  }
};

const handleClick = () => {
  router.push({ name: "instructor-feed" });
};

const course = CourseStore.course;

RecommendationsStore.setRecommendations(course);

const selectIndustryPractitioner = (practitioner) => {
  practitioner.opened = false;
  RecommendationsStore.selectPractitioner(practitioner.id);
  RecommendationsStore.$persist();
};

const saveSelectedPractitioners = (data) => {
  loading.value = true;
  InstructorService.selectIndustryPractitioner(
    course,
    RecommendationsStore.selectedPractitioner,
    data["msg"],
    RecommendationsStore.recommendations.map((el) => el.id)
  ).then(
    (response) => {
      loading.value = false;
      showToast("Message sent successfully to selected industry practitioner.");
      router.push({ name: "home" });
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.items.message) ||
        error.message ||
        error.toString();
    }
  );
};

const getPractitionerExperience = (e, industryPractitioner) => {
  console.log(e);
  e.stopPropagation();
  loading.value = true;
  successful.value = false;
  currIndustryPractitioner.value = industryPractitioner;
  InstructorService.getPastExperienceDetails(industryPractitioner.id).then(
    (response) => {
      console.log(response.data);
      pastExperienceData.value = response.data;
      successful.value = true;
      loading.value = false;
      isPractitionerExperienceVisible.value = true;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.items.message) ||
        error.message ||
        error.toString();
    }
  );
};

const windowResize = () => {
  if (window.innerWidth < 600) {
    FeedStore.recommendationLayout = "grid";
  }
};

windowResize();
window.addEventListener("resize", windowResize);
</script>

<style scoped>
.data-view-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  gap: 1rem;
  text-align: center;
}

.text-wrap {
  white-space: normal; /* Allows text to wrap */
  word-wrap: break-word; /* Breaks long words */
  overflow-wrap: break-word; /* Modern equivalent for long words */
}

.grid-container-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.recommendation-list:nth-child(odd) {
  background-color: rgba(15, 20, 25, 0.1);
}

.recommendation-img-card {
  width: 6rem;
  height: 6rem;
  margin: 0 auto 10px;
  display: block;
}

.number {
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 50%;
  color: black;
  font-weight: bold;
  flex: none;
}

.layout-button >>> .p-highlight {
  background: #0d6efd !important;
  border-color: #0d6efd !important;
}

div.gallery {
  min-height: 12rem;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: #dfe7ef;
  background-color: #fff;
  display: flex;
  text-align: center;
  align-items: center;
}

div.gallery:hover {
  border: 1px solid #777;
}

div.image-container {
  overflow: hidden;
  flex: none;
  margin-left: 2rem;
  margin-right: 2rem;
}

div.gallery img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.table {
  min-width: fit-content;
  box-shadow: 0px 0px 10px 4px rgb(0 0 0 / 30%);
  border-radius: 6px;
  background: #fafafa;
  margin: 1rem auto;
}

thead td {
  text-align: center;
}

td {
  border: 2px solid #f0f0f0;
  border-collapse: collapse;
  padding: 0.2em 2em;
  font-size: larger;
}

.past-exp-button {
  border: none;
  background: none;
  color: var(--link-color);
}

.more-details {
  cursor: pointer;
  color: #1a73e8;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .layout-button {
    display: none;
  }
}

.v-enter-active {
  transition: opacity 0.4s linear;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
