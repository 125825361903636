import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "grid-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "min-height pt-3" }
const _hoisted_5 = { class: "back-grid mb-3" }
const _hoisted_6 = {
  key: 0,
  style: {"display":"flex","justify-content":"space-evenly","align-items":"center"}
}
const _hoisted_7 = { class: "table" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = {
  key: 2,
  style: {"font-style":"italic"}
}
const _hoisted_11 = ["href"]
const _hoisted_12 = {
  key: 3,
  style: {"font-style":"italic"}
}
const _hoisted_13 = { class: "table" }
const _hoisted_14 = ["href"]
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { key: 2 }

import { ref, watch } from "vue";
import InstructorService from "@/services/instructor.service.js";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useRecommendationsStore } from "@/stores/RecommendationsStore";
import { useRouter } from "vue-router";
import PractitionerSidebar from "@/components/PractitionerSidebar.vue";
import InstructorSidebar from "@/components/instructor/InstructorSidebar.vue";
import BackButton from "@/components/user/BackButton.vue";
import { valid } from "@/utils";


export default /*@__PURE__*/_defineComponent({
  __name: 'PractitionerProfileView',
  setup(__props) {

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const RecommendationsStore = useRecommendationsStore();
const router = useRouter();
const currentUser = AuthStore.auth;

let course = CourseStore.course;
let industryPractitioner = ref([]);
let loading = ref(false);
let successful = ref(false);
let message = ref("");

const getIndustryPractitionerProfile = (industryPractitionerUserId) => {
  loading.value = true;
  InstructorService.getIndustryPractitionerProfile(
    industryPractitionerUserId
  ).then(
    (response) => {
      industryPractitioner.value = response.data;
      successful.value = true;
      loading.value = false;
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

getIndustryPractitionerProfile(CourseStore.practitionerId);

watch(
  () => RecommendationsStore.practitionerId,
  (newPractitionerId) => {
    if (newPractitionerId > 0)
      getIndustryPractitionerProfile(newPractitionerId);
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(currentUser)?.roles === 'INDUSTRYPRACTITIONER')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(PractitionerSidebar)
        ]))
      : (_unref(currentUser)?.roles === 'INSTRUCTOR')
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(InstructorSidebar)
          ]))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(BackButton),
        _cache[0] || (_cache[0] = _createElementVNode("h2", { style: {"text-align":"center","font-weight":"bold"} }, " Industry Practitioner Details ", -1))
      ]),
      (_unref(successful) && _unref(industryPractitioner))
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", null, [
              (_unref(RecommendationsStore)?.count > 1)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    class: "prev-icon",
                    title: "View Previous Practitioner",
                    onClick: _unref(RecommendationsStore).moveToPrev,
                    icon: "fa-solid fa-chevron-left"
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", null, [
              _cache[21] || (_cache[21] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, " Practitioner’s Basic Information ", -1)),
              _createElementVNode("table", _hoisted_7, [
                _createElementVNode("tr", null, [
                  _cache[1] || (_cache[1] = _createElementVNode("td", null, "Name", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.user?.firstName +
                  " " +
                  _unref(industryPractitioner)?.user?.lastName), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[2] || (_cache[2] = _createElementVNode("td", null, "Title", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.user?.title || "N/A"), 1)
                ]),
                (_unref(valid)(_unref(industryPractitioner)?.user?.pronouns))
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_8, [
                      _cache[3] || (_cache[3] = _createElementVNode("td", null, "Pronouns", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.user?.pronouns), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_unref(valid)(_unref(industryPractitioner)?.position))
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_9, [
                      _cache[4] || (_cache[4] = _createElementVNode("td", null, "Position", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.position || "N/A"), 1)
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("tr", null, [
                  _cache[5] || (_cache[5] = _createElementVNode("td", null, "Years of Experience", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.yearsOfExp || "N/A"), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[6] || (_cache[6] = _createElementVNode("td", null, "Level of Education", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.levelEducation || "N/A"), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[7] || (_cache[7] = _createElementVNode("td", null, "Area of Specialization", -1)),
                  _createElementVNode("td", null, _toDisplayString(JSON.parse(_unref(industryPractitioner)?.expertise || '["N/A"]').join(
                    ", "
                  )), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[8] || (_cache[8] = _createElementVNode("td", null, "Professional License", -1)),
                  _createElementVNode("td", null, _toDisplayString(JSON.parse(
                    _unref(industryPractitioner)?.profLicense || '["N/A"]'
                  ).join(", ")), 1)
                ]),
                (_unref(course)?.status === '10' || _unref(course)?.status === '20')
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_10, [
                      _cache[9] || (_cache[9] = _createElementVNode("td", null, "Email", -1)),
                      _createElementVNode("td", null, [
                        _createElementVNode("a", {
                          href: 'mailto:' + _unref(industryPractitioner)?.user?.email
                        }, _toDisplayString(_unref(industryPractitioner)?.user?.email), 9, _hoisted_11)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (
                (_unref(course)?.status === '10' || _unref(course)?.status === '20') &&
                _unref(industryPractitioner)?.user?.phone === 'True'
              )
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_12, [
                      _cache[10] || (_cache[10] = _createElementVNode("td", null, "Phone Number", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.user?.phoneNumber), 1)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _cache[22] || (_cache[22] = _createElementVNode("h5", { class: "text-align-center font-italic font-bold" }, " Practitioner’s Organization Basic Information ", -1)),
              _createElementVNode("table", _hoisted_13, [
                _createElementVNode("tr", null, [
                  _cache[11] || (_cache[11] = _createElementVNode("td", null, "Organization", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.user?.nameOrg || "N/A"), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[12] || (_cache[12] = _createElementVNode("td", null, "Location", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.city + " " + _unref(industryPractitioner)?.state), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[13] || (_cache[13] = _createElementVNode("td", null, "Type of Organization", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.type || "N/A"), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[14] || (_cache[14] = _createElementVNode("td", null, "Size of Organization", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.size || "N/A"), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[15] || (_cache[15] = _createElementVNode("td", null, "Gender Diversity of Organization", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.genderDiv || "N/A"), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[16] || (_cache[16] = _createElementVNode("td", null, "Racial Diversity of Organization", -1)),
                  _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.ethnicDiv || "N/A"), 1)
                ]),
                _createElementVNode("tr", null, [
                  _cache[17] || (_cache[17] = _createElementVNode("td", null, "Website of Organization", -1)),
                  _createElementVNode("td", null, [
                    (_unref(industryPractitioner)?.website)
                      ? (_openBlock(), _createElementBlock("a", {
                          key: 0,
                          href: 
                    _unref(industryPractitioner).website.startsWith('http')
                      ? _unref(industryPractitioner).website
                      : 'https://' + _unref(industryPractitioner).website
                  ,
                          target: "_blank"
                        }, _toDisplayString(_unref(industryPractitioner).website), 9, _hoisted_14))
                      : _createCommentVNode("", true)
                  ])
                ]),
                (_unref(valid)(_unref(industryPractitioner)?.typeProject))
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_15, [
                      _cache[18] || (_cache[18] = _createElementVNode("td", null, "Types of Projects Executed", -1)),
                      _createElementVNode("td", null, _toDisplayString(JSON.parse(
                    _unref(industryPractitioner)?.typeProject || '["N/A"]'
                  ).join(", ")), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_unref(valid)(_unref(industryPractitioner)?.typeTechnology))
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_16, [
                      _cache[19] || (_cache[19] = _createElementVNode("td", null, " Types of Technologies Adopted by Practitioner's Organization ", -1)),
                      _createElementVNode("td", null, _toDisplayString(JSON.parse(
                    _unref(industryPractitioner)?.typeTechnology || '["N/A"]'
                  ).join(", ")), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_unref(valid)(_unref(industryPractitioner)?.organPolicy))
                  ? (_openBlock(), _createElementBlock("tr", _hoisted_17, [
                      _cache[20] || (_cache[20] = _createElementVNode("td", null, "Company's Effort to Support Student Development", -1)),
                      _createElementVNode("td", null, _toDisplayString(_unref(industryPractitioner)?.organPolicy || "N/A"), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", null, [
              (_unref(RecommendationsStore)?.count > 1)
                ? (_openBlock(), _createBlock(_component_font_awesome_icon, {
                    key: 0,
                    class: "next-icon",
                    title: "View Next Practitioner",
                    onClick: _unref(RecommendationsStore).moveToNext,
                    icon: "fa-solid fa-chevron-right"
                  }, null, 8, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

})