import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "form-group"
}
const _hoisted_2 = {
  key: 1,
  class: "form-group"
}
const _hoisted_3 = { class: "form-group text-align-center" }
const _hoisted_4 = ["disabled"]
const _hoisted_5 = { class: "spinner-border spinner-border-sm" }

import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Multiselect from "@vueform/multiselect";
import InstructorService from "@/services/instructor.service.js";
import { onInvalidSubmit, showToast, showErrorToast } from "@/utils";
import { useRouter } from "vue-router";
import { useConfigStore } from "@/stores/ConfigStore";

const MED_TEXT_MAX_LENGTH = 200;


export default /*@__PURE__*/_defineComponent({
  __name: 'ResourcesRequestForm',
  props: {
    action: {}
  },
  emits: ["reloadResourceRequest"],
  setup(__props: any, { emit: __emit }) {

const ConfigStore = useConfigStore();
const router = useRouter();

const resourceRequestFields = ConfigStore.config.filter(
  (el) => el.table === "resource_request"
);

let loading = ref(false);
let message = ref("");

const onInputWithNotification = (maxLength, event) => {
  const value = event.target.value;
  if (value.length > maxLength) {
    event.target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit reached`);
  }
};

// eslint-disable-next-line no-undef
const emits = __emit;

const schema = yup.object().shape({
  fields: yup.array().default(resourceRequestFields),
  resourceType: yup
    .string()
    .label("Resource Type")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "resourceType")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  description: yup
    .string()
    .label("Description")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "description")?.disabled,
      then: (schema) =>
        schema
          .required()
          .max(200, "Description cannot be more than 200 characters!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
});

const saveResourcesRequest = (data, { resetForm }) => {
  loading.value = true;
  InstructorService.saveResourcesRequest(data, props.action).then(
    (response) => {
      loading.value = false;
      emits("reloadResourceRequest");
      showToast("Your resources request has been raised/updated.");
      resetForm();
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

// eslint-disable-next-line no-undef
const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "font-color-red" }, "* required field", -1)),
    _createVNode(_unref(Form), {
      onSubmit: saveResourcesRequest,
      onInvalidSubmit: _unref(onInvalidSubmit),
      "validation-schema": _unref(schema)
    }, {
      default: _withCtx(({ values }) => [
        (
          !_unref(resourceRequestFields).find((el) => el.name === 'resourceType')
            ?.disabled
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _cache[1] || (_cache[1] = _createElementVNode("label", null, [
                _createTextVNode("Resource Type "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), {
                name: "resourceType",
                type: "text",
                class: "dropdown"
              }, {
                default: _withCtx(({ field }) => [
                  _createVNode(_unref(Multiselect), _mergeProps(field, {
                    modelValue: values.resourceType,
                    "onUpdate:modelValue": ($event: any) => ((values.resourceType) = $event),
                    options: ['Project Pictures', 'Video', 'Document'],
                    mode: "single",
                    searchable: true
                  }), null, 16, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_unref(ErrorMessage), {
                name: "resourceType",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        (
          !_unref(resourceRequestFields).find((el) => el.name === 'description')
            ?.disabled
        )
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _cache[2] || (_cache[2] = _createElementVNode("label", { for: "description" }, [
                _createTextVNode("Description "),
                _createElementVNode("span", { class: "super font-color-red" }, "*")
              ], -1)),
              _createVNode(_unref(Field), { name: "description" }, {
                default: _withCtx(({ field }) => [
                  _createElementVNode("input", _mergeProps(field, {
                    class: "form-control",
                    type: "text",
                    maxlength: MED_TEXT_MAX_LENGTH,
                    onInput: _cache[0] || (_cache[0] = ($event: any) => (onInputWithNotification(MED_TEXT_MAX_LENGTH, $event)))
                  }), null, 16),
                  _createTextVNode(" " + _toDisplayString(values.description ? values.description.length : 0) + " / " + _toDisplayString(MED_TEXT_MAX_LENGTH) + " characters ", 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_unref(ErrorMessage), {
                name: "description",
                class: "error-feedback"
              })
            ]))
          : _createCommentVNode("", true),
        _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", {
            type: "submit",
            class: "btn btn-primary btn-block",
            disabled: _unref(loading)
          }, [
            _withDirectives(_createElementVNode("span", _hoisted_5, null, 512), [
              [_vShow, _unref(loading)]
            ]),
            _cache[3] || (_cache[3] = _createElementVNode("span", null, "Request Resources", -1))
          ], 8, _hoisted_4)
        ])
      ]),
      _: 1
    }, 8, ["onInvalidSubmit", "validation-schema"])
  ]))
}
}

})