<template>
  <div>
    <span class="font-color-red">* required field</span>
    <Form
      @submit="saveResourcesRequest"
      @invalid-submit="onInvalidSubmit"
      v-slot="{ values }"
      :validation-schema="schema"
    >
      <div
        class="form-group"
        v-if="
          !resourceRequestFields.find((el) => el.name === 'resourceType')
            ?.disabled
        "
      >
        <label>Resource Type <span class="super font-color-red">*</span></label>
        <Field
          name="resourceType"
          type="text"
          class="dropdown"
          v-slot="{ field }"
        >
          <Multiselect
            v-bind="field"
            v-model="values.resourceType"
            :options="['Project Pictures', 'Video', 'Document']"
            mode="single"
            :searchable="true"
          />
        </Field>
        <ErrorMessage name="resourceType" class="error-feedback" />
      </div>
      <div
        class="form-group"
        v-if="
          !resourceRequestFields.find((el) => el.name === 'description')
            ?.disabled
        "
      >
        <label for="description"
          >Description <span class="super font-color-red">*</span></label
        >
        <Field name="description" v-slot="{ field }">
          <input
            v-bind="field"
            class="form-control"
            type="text"
            :maxlength="MED_TEXT_MAX_LENGTH"
            @input="onInputWithNotification(MED_TEXT_MAX_LENGTH, $event)"
          />
          {{ values.description ? values.description.length : 0 }}
          / {{ MED_TEXT_MAX_LENGTH }} characters
        </Field>
        <ErrorMessage name="description" class="error-feedback" />
      </div>
      <br />
      <div class="form-group text-align-center">
        <button
          type="submit"
          class="btn btn-primary btn-block"
          :disabled="loading"
        >
          <span
            v-show="loading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span>Request Resources</span>
        </button>
      </div>
    </Form>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { Form, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import Multiselect from "@vueform/multiselect";
import InstructorService from "@/services/instructor.service.js";
import { onInvalidSubmit, showToast, showErrorToast } from "@/utils";
import { useRouter } from "vue-router";
import { useConfigStore } from "@/stores/ConfigStore";

const MED_TEXT_MAX_LENGTH = 200;

const ConfigStore = useConfigStore();
const router = useRouter();

const resourceRequestFields = ConfigStore.config.filter(
  (el) => el.table === "resource_request"
);

let loading = ref(false);
let message = ref("");

const onInputWithNotification = (maxLength, event) => {
  const value = event.target.value;
  if (value.length > maxLength) {
    event.target.value = value.substring(0, maxLength);
    showErrorToast(`Maximum character limit reached`);
  } else if (value.length === maxLength) {
    showErrorToast(`Maximum character limit reached`);
  }
};

// eslint-disable-next-line no-undef
const emits = defineEmits(["reloadResourceRequest"]);

const schema = yup.object().shape({
  fields: yup.array().default(resourceRequestFields),
  resourceType: yup
    .string()
    .label("Resource Type")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "resourceType")?.disabled,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional().nullable(),
    }),
  description: yup
    .string()
    .label("Description")
    .when("fields", {
      is: (val) => !val.find((el) => el.name === "description")?.disabled,
      then: (schema) =>
        schema
          .required()
          .max(200, "Description cannot be more than 200 characters!"),
      otherwise: (schema) => schema.optional().nullable(),
    }),
});

const saveResourcesRequest = (data, { resetForm }) => {
  loading.value = true;
  InstructorService.saveResourcesRequest(data, props.action).then(
    (response) => {
      loading.value = false;
      emits("reloadResourceRequest");
      showToast("Your resources request has been raised/updated.");
      resetForm();
    },
    (error) => {
      loading.value = false;
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
    }
  );
};

// eslint-disable-next-line no-undef
const props = defineProps<{
  action: string;
}>();
</script>

<style scoped></style>
