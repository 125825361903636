import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/images/courseType/Judge-for-Project.jpg'
import _imports_1 from '@/assets/images/courseType/Mentor-for-Project.jpg'
import _imports_2 from '@/assets/images/courseType/Guest-Lecture.jpg'
import _imports_3 from '@/assets/images/courseType/Seminar.jpg'
import _imports_4 from '@/assets/images/courseType/Labs.jpg'
import _imports_5 from '@/assets/images/courseType/Workshop.jpg'
import _imports_6 from '@/assets/images/courseType/Sponsor-for-Project.png'
import _imports_7 from '@/assets/images/courseType/Site-Visit.jpg'


const _hoisted_1 = { class: "border-1 surface-border surface-card border-round p-2" }
const _hoisted_2 = { class: "cursor-pointer" }
const _hoisted_3 = {
  key: 0,
  id: "profile-img",
  src: _imports_0,
  class: "recommendation-img-card"
}
const _hoisted_4 = {
  key: 1,
  id: "profile-img",
  src: _imports_1,
  class: "recommendation-img-card"
}
const _hoisted_5 = {
  key: 2,
  id: "profile-img",
  src: _imports_2,
  class: "recommendation-img-card"
}
const _hoisted_6 = {
  key: 3,
  id: "profile-img",
  src: _imports_3,
  class: "recommendation-img-card"
}
const _hoisted_7 = {
  key: 4,
  id: "profile-img",
  src: _imports_4,
  class: "recommendation-img-card"
}
const _hoisted_8 = {
  key: 5,
  id: "profile-img",
  src: _imports_5,
  class: "recommendation-img-card"
}
const _hoisted_9 = {
  key: 6,
  id: "profile-img",
  src: _imports_6,
  class: "recommendation-img-card"
}
const _hoisted_10 = {
  key: 7,
  id: "profile-img",
  src: _imports_7,
  class: "recommendation-img-card"
}
const _hoisted_11 = { class: "mx-2" }
const _hoisted_12 = {
  key: 0,
  class: "highlight-selected-time"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "text-align-center" }
const _hoisted_15 = { class: "flex flex-direction-column justify-content-between text-align-center mt-2" }
const _hoisted_16 = { class: "button-container" }
const _hoisted_17 = { class: "p-2" }

import { ref } from "vue";
import { CourseRequest } from "@/types";
import { sameDate } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'CourseRequestGridItem',
  props: {
    course: {}
  },
  setup(__props: any) {

const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const router = useRouter();

let loading = false;
let successful = false;
let message = ref("");
const content = ref([]);
const visible = ref(false);

// eslint-disable-next-line no-undef
const props = __props;

const viewCourseRequest = (course) => {
  CourseStore.addCourse(course);
  router.push({ name: "view-course-request-prac" });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", {
          class: "flex flex-direction-column lg:flex-row align-center justify-space-between",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (viewCourseRequest(_ctx.course)))
        }, [
          (_ctx.course.courseRequestType === 'Judge for Project')
            ? (_openBlock(), _createElementBlock("img", _hoisted_3))
            : (_ctx.course.courseRequestType === 'Mentor for Project')
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : (_ctx.course.courseRequestType === 'Guest Lecture')
                ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                : (_ctx.course.courseRequestType === 'Seminar')
                  ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                  : (_ctx.course.courseRequestType === 'Labs')
                    ? (_openBlock(), _createElementBlock("img", _hoisted_7))
                    : (_ctx.course.courseRequestType === 'Workshop')
                      ? (_openBlock(), _createElementBlock("img", _hoisted_8))
                      : (_ctx.course.courseRequestType === 'Sponsor for Project')
                        ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                        : (_ctx.course.courseRequestType === 'Site Visit')
                          ? (_openBlock(), _createElementBlock("img", _hoisted_10))
                          : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_11, [
            (
                _ctx.course['course_request_x_practitioners'][0] &&
                (_ctx.course['course_request_x_practitioners'][0].status === '60' ||
                  _ctx.course['course_request_x_practitioners'][0].status === '10')
              )
              ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(new Date(
                  _ctx.course["course_request_x_practitioners"][0].selectedDate
                ).toLocaleString()), 1))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(JSON.parse(_ctx.course.date), (item, index) => {
                  return (_openBlock(), _createElementBlock("div", { key: index }, [
                    _createTextVNode(_toDisplayString(new Date(
                  item + "T" + JSON.parse(_ctx.course.time)[index]
                ).toLocaleString()) + " ", 1),
                    (index != JSON.parse(_ctx.course.date).length - 1)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_13, " OR "))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createTextVNode(_toDisplayString(_ctx.course.user.title +
            " " +
            _ctx.course.user.firstName +
            " " +
            _ctx.course.user.lastName) + " ", 1),
          _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("b", null, _toDisplayString(_ctx.course.user.nameOrg), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("button", {
              class: "view-more",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (viewCourseRequest(_ctx.course)))
            }, " View Details ")
          ])
        ])
      ])
    ])
  ]))
}
}

})