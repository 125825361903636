import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/profile.png'
import _imports_1 from '@/assets/images/class.png'
import _imports_2 from '@/assets/images/course_support.png'
import _imports_3 from '@/assets/images/resources.png'
import _imports_4 from '@/assets/images/upload_resources.png'
import _imports_5 from '@/assets/images/view_resources.png'
import _imports_6 from '@/assets/images/feedback.png'
import _imports_7 from '@/assets/images/invite_users.png'
import _imports_8 from '@/assets/images/guide.png'
import _imports_9 from '@/assets/images/settings.png'


const _hoisted_1 = { class: "sidebar sidebar-max min-height" }
const _hoisted_2 = { style: {"text-align":"center"} }
const _hoisted_3 = { class: "bold-text" }
const _hoisted_4 = { style: {"font-weight":"300"} }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { style: {"position":"relative"} }
const _hoisted_9 = { class: "notification-count" }
const _hoisted_10 = { class: "sidebar sidebar-min min-height" }
const _hoisted_11 = { class: "mt-3" }
const _hoisted_12 = { style: {"position":"relative"} }
const _hoisted_13 = { class: "notification-count" }

import { ref } from "vue";
import ProfilePicture from "@/components/user/ProfilePicture.vue";
import ProfileIcon from "@/components/user/ProfileIcon.vue";
import Dialog from "primevue/dialog";
import InviteUsersForm from "@/components/user/InviteUsersForm.vue";
import { useAuthStore } from "@/stores/AuthStore";
import { useCourseStore } from "@/stores/CourseStore";
import { useNotificationStore } from "@/stores/NotificationsStore";
import { useRouter, useRoute } from "vue-router";
import { showErrorToast } from "@/utils";
import { useConfigStore } from "@/stores/ConfigStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstructorSidebar',
  setup(__props) {

const ConfigStore = useConfigStore();
const AuthStore = useAuthStore();
const CourseStore = useCourseStore();
const NotificationStore = useNotificationStore();
const router = useRouter();
const route = useRoute();

const settingsFields = ConfigStore.config.filter(
  (el) => el.table === "settings"
);

let currentUser = AuthStore.auth;
let isVisible = ref(false);
let loading = ref(false);
let message = ref("");

const raiseCourseRequest = () => {
  CourseStore.removeCourse();
  router.push({ name: "course-request" });
};

const showInviteOthers = () => {
  if (AuthStore.profile) isVisible.value = true;
  else
    showErrorToast(
      "Please complete your profile before proceeding to any part of the web platform"
    );
};

NotificationStore.getNotification();

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(ProfilePicture),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_unref(currentUser).firstName + " " + _unref(currentUser).lastName), 1),
        _cache[16] || (_cache[16] = _createElementVNode("br", null, null, -1)),
        _createElementVNode("span", _hoisted_4, [
          _createTextVNode(_toDisplayString(_unref(currentUser).title), 1),
          _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1)),
          (_unref(currentUser).profile)
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
                _createTextVNode(_toDisplayString(_unref(currentUser).profile.department) + " ", 1),
                _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1))
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("i", null, [
          (_unref(currentUser).nameOrg)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                _createTextVNode(_toDisplayString(_unref(currentUser).nameOrg) + " ", 1),
                _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1))
              ]))
            : _createCommentVNode("", true),
          (_unref(currentUser).profile)
            ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_unref(currentUser).profile.city + " " + _unref(currentUser).profile.state), 1))
            : _createCommentVNode("", true)
        ]),
        _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1)),
        _cache[18] || (_cache[18] = _createElementVNode("br", null, null, -1)),
        _createVNode(_component_router_link, {
          to: {
          name: 'my-user-profile',
          params: { userId: _unref(currentUser).id },
        }
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path.startsWith('/profile')
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
              title: "View/edit your profile information."
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("img", {
                src: _imports_0,
                class: "sidebar-image-large"
              }, null, -1),
              _createElementVNode("span", { class: "ml-3" }, "Profile", -1)
            ]), 2)
          ]),
          _: 1
        }, 8, ["to"]),
        _createVNode(_component_router_link, { to: {
          name: 'instructor-classes',
        } }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path == '/instructor/classes'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
              title: "Add and edit the classes (courses) you are teaching."
            }, _cache[6] || (_cache[6] = [
              _createElementVNode("img", {
                src: _imports_1,
                class: "sidebar-image-large"
              }, null, -1),
              _createElementVNode("span", { class: "ml-2" }, "My Classes", -1)
            ]), 2)
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: _normalizeClass(["sidebar-button pl-3", [
          _unref(route).path.startsWith('/instructor/course')
            ? 'selected-sidebar-button'
            : 'unselected-sidebar-button',
        ]]),
          onClick: raiseCourseRequest,
          title: "Place a request for course-support to see recommended practitioners."
        }, _cache[7] || (_cache[7] = [
          _createElementVNode("img", {
            src: _imports_2,
            class: "sidebar-image-large"
          }, null, -1),
          _createElementVNode("span", { class: "ml-2" }, "Request Course-Support", -1)
        ]), 2),
        _createVNode(_component_router_link, { to: { name: 'new-resources' } }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path === '/instructor/resources/new'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
              title: "Place a request for instructional pictures, videos, and documents."
            }, _cache[8] || (_cache[8] = [
              _createElementVNode("img", {
                src: _imports_3,
                class: "sidebar-image-large"
              }, null, -1),
              _createElementVNode("span", { class: "ml-2" }, "Request Resources", -1)
            ]), 2)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: { name: 'upload' } }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path === '/upload'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
              title: "Share instructional pictures, videos, and documents with other instructors."
            }, _cache[9] || (_cache[9] = [
              _createElementVNode("img", {
                src: _imports_4,
                class: "sidebar-image-large",
                alt: "Upload Resources"
              }, null, -1),
              _createElementVNode("span", { class: "ml-3" }, "Upload Resources", -1)
            ]), 2)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: { name: 'instructor-view-resources' },
          class: "nav-link"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path === '/instructor/resources/view'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
              title: "Access instructional pictures, videos, and documents shared by other users."
            }, _cache[10] || (_cache[10] = [
              _createElementVNode("img", {
                src: _imports_5,
                class: "sidebar-image-large"
              }, null, -1),
              _createElementVNode("span", { class: "ml-3" }, "View Resources", -1)
            ]), 2)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, { to: { name: 'view-notifications-ins' } }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path === '/instructor/notifications'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
              title: "Your notifications will appear here."
            }, [
              _createVNode(_component_font_awesome_icon, {
                class: "sidebar-icon",
                icon: "fa-regular fa-bell",
                style: {"color":"#fcc62a"}
              }),
              _createElementVNode("span", _hoisted_8, [
                _createElementVNode("span", _hoisted_9, _toDisplayString(_unref(NotificationStore).count), 1)
              ]),
              _cache[11] || (_cache[11] = _createElementVNode("span", { class: "ml-3" }, "View Notifications", -1))
            ], 2)
          ]),
          _: 1
        }),
        _createVNode(_component_router_link, {
          to: { name: 'feedback' },
          class: "nav-link"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path === '/feedback'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
              title: "Provide feedback to the Admin here."
            }, _cache[12] || (_cache[12] = [
              _createElementVNode("img", {
                src: _imports_6,
                class: "sidebar-image-large"
              }, null, -1),
              _createElementVNode("span", { class: "ml-3" }, "Feedback", -1)
            ]), 2)
          ]),
          _: 1
        }),
        _createElementVNode("div", {
          class: _normalizeClass(["sidebar-button pl-3", [
          _unref(isVisible) ? 'selected-sidebar-button' : 'unselected-sidebar-button',
        ]]),
          onClick: showInviteOthers,
          title: "Invite others to ConPEC here."
        }, _cache[13] || (_cache[13] = [
          _createElementVNode("img", {
            src: _imports_7,
            class: "sidebar-image-large"
          }, null, -1),
          _createElementVNode("span", { class: "ml-3" }, "Invite Potential Users", -1)
        ]), 2),
        _createVNode(_component_router_link, {
          to: { name: 'guide' },
          class: "nav-link"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path === '/guide/'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
              title: "Help/Guide"
            }, _cache[14] || (_cache[14] = [
              _createElementVNode("img", {
                src: _imports_8,
                class: "sidebar-image-large"
              }, null, -1),
              _createElementVNode("span", { class: "ml-3" }, "Help/Guide", -1)
            ]), 2)
          ]),
          _: 1
        }),
        (
          !_unref(settingsFields).find((el) => el.name === 'email')?.disabled ||
          !_unref(settingsFields).find((el) => el.name === 'reasonDelete')?.disabled
        )
          ? (_openBlock(), _createBlock(_component_router_link, {
              key: 0,
              to: { name: 'settings' },
              class: "nav-link"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path === '/settings/'
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
                  title: "Settings"
                }, _cache[15] || (_cache[15] = [
                  _createElementVNode("img", {
                    src: _imports_9,
                    class: "sidebar-image-large"
                  }, null, -1),
                  _createElementVNode("span", { class: "ml-3" }, "Settings", -1)
                ]), 2)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(ProfileIcon),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_router_link, {
            to: {
            name: 'my-user-profile',
            params: { userId: _unref(currentUser).id },
          }
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path.startsWith('/profile')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "View/edit your profile information."
              }, _cache[19] || (_cache[19] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_router_link, { to: {
            name: 'instructor-classes',
          } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path == '/instructor/classes'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Add and edit the classes (courses) you are teaching."
              }, _cache[20] || (_cache[20] = [
                _createElementVNode("img", {
                  src: _imports_1,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createElementVNode("div", {
            class: _normalizeClass(["sidebar-button pl-3", [
            _unref(route).path.startsWith('/instructor/course')
              ? 'selected-sidebar-button'
              : 'unselected-sidebar-button',
          ]]),
            onClick: raiseCourseRequest,
            title: "Place a request for course-support to see recommended practitioners."
          }, _cache[21] || (_cache[21] = [
            _createElementVNode("img", {
              src: _imports_2,
              class: "sidebar-image-large"
            }, null, -1)
          ]), 2),
          _createVNode(_component_router_link, { to: { name: 'new-resources' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/instructor/resources/new'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Place a request for instructional pictures, videos, and documents."
              }, _cache[22] || (_cache[22] = [
                _createElementVNode("img", {
                  src: _imports_3,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'upload' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path.startsWith('/upload')
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Share instructional pictures, videos, and documents with other instructors."
              }, _cache[23] || (_cache[23] = [
                _createElementVNode("img", {
                  src: _imports_4,
                  class: "sidebar-image-large",
                  alt: "Upload Resources"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: { name: 'instructor-view-resources' },
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/instructor/uploads'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Access instructional pictures, videos, and documents shared by other users."
              }, _cache[24] || (_cache[24] = [
                _createElementVNode("img", {
                  src: _imports_5,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'view-notifications-ins' } }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/instructor/notifications'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Your notifications will appear here."
              }, [
                _createVNode(_component_font_awesome_icon, {
                  class: "sidebar-icon",
                  icon: "fa-regular fa-bell",
                  style: {"color":"#fcc62a"}
                }),
                _createElementVNode("span", _hoisted_12, [
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_unref(NotificationStore).count), 1)
                ])
              ], 2)
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, {
            to: { name: 'feedback' },
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/feedback'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Provide feedback to the Admin here."
              }, _cache[25] || (_cache[25] = [
                _createElementVNode("img", {
                  src: _imports_6,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          _createElementVNode("div", {
            class: _normalizeClass(["sidebar-button pl-3", [
            _unref(isVisible) ? 'selected-sidebar-button' : 'unselected-sidebar-button',
          ]]),
            onClick: showInviteOthers,
            title: "Invite others to ConPEC here."
          }, _cache[26] || (_cache[26] = [
            _createElementVNode("img", {
              src: _imports_7,
              class: "sidebar-image-large"
            }, null, -1)
          ]), 2),
          _createVNode(_component_router_link, {
            to: { name: 'guide' },
            class: "nav-link"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/guide/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                title: "Help/Guide"
              }, _cache[27] || (_cache[27] = [
                _createElementVNode("img", {
                  src: _imports_8,
                  class: "sidebar-image-large"
                }, null, -1)
              ]), 2)
            ]),
            _: 1
          }),
          (
            !_unref(settingsFields).find((el) => el.name === 'email')?.disabled ||
            !_unref(settingsFields).find((el) => el.name === 'reasonDelete')?.disabled
          )
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'settings' },
                class: "nav-link"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(["sidebar-button pl-3", [
              _unref(route).path === '/settings/'
                ? 'selected-sidebar-button'
                : 'unselected-sidebar-button',
            ]]),
                    title: "Settings"
                  }, _cache[28] || (_cache[28] = [
                    _createElementVNode("img", {
                      src: _imports_9,
                      class: "sidebar-image-large"
                    }, null, -1)
                  ]), 2)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_unref(Dialog), {
      visible: _unref(isVisible),
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_isRef(isVisible) ? (isVisible).value = $event : isVisible = $event)),
      modal: "",
      header: "Invite people from your network",
      style: { width: '50vw' },
      breakpoints: { '768px': '90vw' }
    }, {
      default: _withCtx(() => [
        _createVNode(InviteUsersForm, {
          onHideDialog: _cache[0] || (_cache[0] = ($event: any) => (_isRef(isVisible) //@ts-ignore
 ? isVisible.value = false : isVisible = false))
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})